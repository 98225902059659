import { Label } from '@progress/kendo-react-labels';
import cloneDeep from 'lodash.clonedeep';
import { defaultMarkdownSerializer } from 'prosemirror-markdown';
import { useContext, useEffect, useState } from 'react';
import { Col, Row, Tab, Tabs } from 'react-bootstrap';
import { HtmlEditor } from '../../../components/editor/html-editor';
import { StandardInput, StandardTextarea } from '../../../components/forms';
import { MarkdownEditor } from '../../../components/markdown/markdown';
import { Questionnaire } from '../../../components/questionnaire/questionnaire';
import { GameDocumentContext } from '../../../contexts/game-document';
import { uuid } from '../../../types/common-helper';
import { GameDocument } from '../../../types/game-document';
import {
  ResourceEntity,
  TaskContentEntity
} from '../../../types/game-document/entities';
import {
  AnswerType,
  FormCustomFeedback,
  ManualScoring,
  ScoringReductionEntity,
  TaskContentAnswer,
  TaskContentForm
} from '../../../types/game-document/entities/task-content';
import RequiredFields from '../../../types/required-fields';
import {
  AddDefaultAnswers,
  AddResourceAsync,
  AddResourceEntityAsync,
  DeleteResourceAsync,
  UpdateGameDocState,
  UpdateResourceAsync
} from '../../../utils/game-document';
import {
  AddAnswerTaskContentForm,
  AddNewTaskContentForm,
  CopyTaskContentFormQuestion,
  DefaultCustomFeedback,
  DeleteAnswerTaskContentForm,
  DeleteTaskContentForm,
  UpdateTaskContentAsync,
  UpdateTaskContentFormAnswers
} from '../../../utils/game-document/assets';
import {
  ResourceWindow,
  UploadedImage
} from '../image-resource/resource-window';
import { ComboboxInput } from '../../../components/form-input';

type FileUpload = 'Background' | 'Logo' | 'Media';
export interface TaskContentFullEditorProps {
  entity: TaskContentEntity;
  requiredFields?: RequiredFields<TaskContentEntity>[];
  handleEntityChange?: (entityKey: string, value: string) => void;
}

export interface TaskContentType {
  id?: AnswerType;
  description?: string;
}

export const TaskContentFullEditor = ({
  entity,
  requiredFields,
  handleEntityChange = () => {}
}: TaskContentFullEditorProps) => {
  const [state, setState] = useContext(GameDocumentContext);
  const [resources, setResources] = useState<ResourceEntity[]>([]);
  const [fileUpload, setFileUpload] = useState<FileUpload>();
  const [isEditMedia, setIsEditMedia] = useState<boolean>(false);
  const [deleteMediaResource, setDeleteMediaResource] = useState<string>('');
  const [taskContentForm, setTaskContentForm] = useState<TaskContentForm>();
  const [entityEditorIsVisible, setEntityEditorIsVisible] =
    useState<boolean>(false);
  const [editMediaResourceIndex, setEditMediaResourceIndex] = useState<
    number | undefined
  >(undefined);
  const [extensionUploadedFile, setExtensionUploadedFile] =
    useState<string>('');
  const [showDeleteMediaConfirm, setShowDeleteMediaConfirm] =
    useState<boolean>(false);
  const [selectedImageUrl, setSelectedImageUrl] = useState<string>('');

  const [taskContent, setTaskContent] = useState<TaskContentEntity>(
    {} as TaskContentEntity
  );
  const { forms = [] } = taskContent;

  const onAddNewForm = () => {
    AddNewTaskContentForm(state.gameDocument!, taskContent.id).then(
      (response) => {
        setState((prev) => UpdateGameDocState(prev, response));
      }
    );
  };

  const copyForm = (index: number) => {
    CopyTaskContentFormQuestion(
      state.gameDocument!,
      taskContent.id,
      taskContent?.forms![index]?.questionResId!
    ).then((response) => {
      setState((prev) => UpdateGameDocState(prev, response));
    });
  };

  const onDeleteFormHandler = (index: string) => {
    const questionId = forms[parseInt(index)].questionResId;

    DeleteTaskContentForm(
      state.gameDocument!,
      taskContent?.id,
      questionId!
    ).then((response) => {
      setState((prev) => UpdateGameDocState(prev, response!));
    });
  };

  const onAddAnswerHandler = (questionIndex: number) => {
    const questionId = forms[questionIndex].questionResId;

    AddAnswerTaskContentForm(
      state.gameDocument!,
      taskContent?.id,
      questionId!
    ).then((response) => {
      setState((prev) => UpdateGameDocState(prev, response!));
    });
  };

  const onDeleteOptionHandler = (index: number, optionIndex: number) => {
    const questionResId = forms[index].questionResId;
    const answerResId = forms![index!].answers![optionIndex].answerResId;
    DeleteAnswerTaskContentForm(
      state.gameDocument!,
      taskContent?.id,
      questionResId!,
      answerResId
    ).then((response) => {
      setState((prev) => UpdateGameDocState(prev, response!));
    });
  };

  const getAnswersDescription = (form: TaskContentForm) => {
    let answerDescriptions: TaskContentAnswer[] = [];

    form.answers?.forEach((answer: TaskContentAnswer) => {
      answer.description = resources.find(
        (x) => x?.id === answer.answerResId
      )?.value;

      answerDescriptions.push(answer);
    });

    return answerDescriptions;
  };

  const onChangeScoringHandler = (
    formId: string,
    value: number | undefined,
    isManualScoring?: boolean,
    manualScoring?: ManualScoring,
    maxAttempts?: number | undefined,
    pointDepreciation?: number | undefined,
    isScoringReduction?: boolean,
    scoringReduction?: ScoringReductionEntity,
    answersWithScoring?: TaskContentAnswer[]
  ) => {
    let newTaskContent: TaskContentEntity = { ...taskContent };

    const index = newTaskContent?.forms?.findIndex((x) => x.id === formId);

    if (index! > -1) {
      newTaskContent.forms![index!].score = value;
      newTaskContent.forms![index!].isManualScoring = isManualScoring;
      newTaskContent.forms![index!].manualScoring = manualScoring;
      newTaskContent.forms![index!].maxAttempts = maxAttempts;
      newTaskContent.forms![index!].pointDepreciation = pointDepreciation;
      newTaskContent.forms![index!].isScoringReduction = isScoringReduction;
      newTaskContent.forms![index!].scoringReduction = scoringReduction;
      newTaskContent.forms![index!].answers = answersWithScoring;

      UpdateTaskContentAsync(
        state.gameDocument!,
        newTaskContent.id,
        newTaskContent
      ).then((response) => {
        setState((prev) => UpdateGameDocState(prev, response!));
      });
    }
  };

  /**
   * Update custom feedback to task content form
   * @param formId
   * @param customFeedback
   */
  const onCustomFeedbackHandler = (
    formId: string,
    customFeedback: FormCustomFeedback
  ) => {
    let newTaskContent: TaskContentEntity = { ...taskContent };

    const index = newTaskContent?.forms?.findIndex((x) => x?.id === formId);

    if (index! > -1) {
      newTaskContent.forms![index!].customFeedback = customFeedback;

      UpdateTaskContentAsync(
        state.gameDocument!,
        newTaskContent.id,
        newTaskContent
      ).then((response) => {
        setState((prev) => UpdateGameDocState(prev, response!));
      });
    }
  };

  /**
   * Update custom feedback to task content form
   * @param formId
   * @param customFeedback
   */
  const onDeleteMediaHandler = (formId: string, mediaIndex: number) => {
    let newTaskContent: TaskContentEntity = { ...taskContent };

    const index = newTaskContent?.forms?.findIndex((x) => x.id === formId);

    if (index! > -1) {
      newTaskContent.forms![index!].medias.splice(mediaIndex, 1);

      UpdateTaskContentAsync(
        state.gameDocument!,
        newTaskContent.id,
        newTaskContent
      ).then((response) => {
        setState((prev) => UpdateGameDocState(prev, response!));
      });
    }
  };

  const onChangeAnswersHandler = (
    formId: string,
    answers: TaskContentAnswer[]
  ) => {
    UpdateTaskContentFormAnswers(
      state.gameDocument!,
      taskContent.id,
      formId,
      answers
    ).then((response) => {
      setState((prev) => UpdateGameDocState(prev, response!));
    });
  };

  const onChangeAnswerTypeHandler = (index: number, type: AnswerType) => {
    let newTaskContent = { ...taskContent } as TaskContentEntity;
    let updatedGameDocument = state.gameDocument;

    if (newTaskContent.forms && updatedGameDocument) {
      newTaskContent!.forms![index].type = type;

      //Reset the answer every change answer type
      newTaskContent.forms![index].answers = [];

      const customFeedback = newTaskContent.forms[index].customFeedback;

      // check if feedback is enable
      if (customFeedback) {
        if (type === 'checkbox') {
          // should add feedback partial message if answer type is checkbox
          const partialMessageFeedback = {
            isDefaultPartiallyCorrect: true,
            partiallyCorrectMessage:
              'That answer was partially correct! Partial points awarded.',
            partiallyCorrectUrlResId: '',
            partiallyCorrectMessageResId: uuid()
          };

          AddResourceAsync(
            updatedGameDocument,
            'Partial correct feedback message default',
            '',
            'text',
            partialMessageFeedback.partiallyCorrectMessage,
            partialMessageFeedback.partiallyCorrectMessageResId
          ).then((response) => (updatedGameDocument = response));

          newTaskContent.forms[index].customFeedback = {
            ...customFeedback,
            ...partialMessageFeedback
          };
        } else if (['textarea', 'none'].includes(type)) {
          // should remove feedback if answer type paraghraph (textarea) or task manual (none)
          const {
            allCorrectMessageResId,
            allWrongMessageResId,
            partiallyCorrectMessageResId
          } = customFeedback;
          if (allCorrectMessageResId)
            DeleteResourceAsync(
              updatedGameDocument,
              allCorrectMessageResId
            ).then((response) => (updatedGameDocument = response));
          if (allWrongMessageResId)
            DeleteResourceAsync(updatedGameDocument, allWrongMessageResId).then(
              (response) => (updatedGameDocument = response)
            );
          if (partiallyCorrectMessageResId)
            DeleteResourceAsync(
              updatedGameDocument,
              partiallyCorrectMessageResId
            ).then((response) => (updatedGameDocument = response));

          delete newTaskContent.forms[index].customFeedback;
        } else {
          const { partiallyCorrectMessageResId } = customFeedback;
          if (partiallyCorrectMessageResId)
            DeleteResourceAsync(
              updatedGameDocument,
              partiallyCorrectMessageResId
            ).then((response) => (updatedGameDocument = response));

          newTaskContent.forms[index].customFeedback = {
            ...customFeedback,

            isDefaultPartiallyCorrect: true,
            partiallyCorrectMessage: '',
            partiallyCorrectUrlResId: '',
            partiallyCorrectMessageResId: ''
          };
        }
      } else {
        // feedback default should be enable, so if there is no feedback
        // and answer type is not paraghraph (textarea) or task manual (none)
        // it should be added
        if (!['textarea', 'none'].includes(type)) {
          const correctMessageFeedback = {
            isDefaultAllCorrect: true,
            allCorrectMessage: 'Congratulations, you got it!',
            allCorrectUrlResId: '',
            allCorrectMessageResId: uuid()
          };
          const wrongMessageFeedback = {
            isDefaultAllWrong: true,
            allWrongMessage: "That's not the answer!",
            allWrongUrlResId: '',
            allWrongMessageResId: uuid()
          };
          const partialMessageFeedback = {
            isDefaultPartiallyCorrect: true,
            partiallyCorrectMessage:
              'That answer was partially correct! Partial points awarded.',
            partiallyCorrectUrlResId: '',
            partiallyCorrectMessageResId: uuid()
          };

          const defaultFeedback = {
            ...DefaultCustomFeedback(),
            ...correctMessageFeedback,
            ...wrongMessageFeedback,
            ...(type === 'checkbox' && { ...partialMessageFeedback })
          };

          AddResourceAsync(
            updatedGameDocument,
            'All correct feedback message default',
            '',
            'text',
            correctMessageFeedback.allCorrectMessage,
            correctMessageFeedback.allCorrectMessageResId
          ).then((response) => (updatedGameDocument = response));
          AddResourceAsync(
            updatedGameDocument,
            'All wrong feedback message default',
            '',
            'text',
            wrongMessageFeedback.allWrongMessage,
            wrongMessageFeedback.allWrongMessageResId
          ).then((response) => (updatedGameDocument = response));
          if (type === 'checkbox') {
            AddResourceAsync(
              updatedGameDocument,
              'Partial correct feedback message default',
              '',
              'text',
              partialMessageFeedback.partiallyCorrectMessage,
              partialMessageFeedback.partiallyCorrectMessageResId
            ).then((response) => (updatedGameDocument = response));
          }

          newTaskContent.forms[index].customFeedback = defaultFeedback;
        }
      }

      let newAnswerResources: ResourceEntity[] = AddDefaultAnswers(type);

      AddMultipleAnswerForOptions(updatedGameDocument, newAnswerResources);

      newAnswerResources.forEach((responseAnswer) => {
        newTaskContent.forms![index].answers?.push({
          answerResId: responseAnswer.id
        });
      });

      UpdateTaskContentAsync(
        updatedGameDocument,
        newTaskContent.id,
        newTaskContent
      ).then((response) => {
        setState((prev) => UpdateGameDocState(prev, response!));
      });
    }
  };

  const AddMultipleAnswerForOptions = async (
    gameDocument: GameDocument,
    resources: ResourceEntity[]
  ) => {
    resources.forEach((resource) => {
      AddResourceEntityAsync(gameDocument, resource).then((response) => {
        setState((prev) => UpdateGameDocState(prev, response!));
      });
    });
  };

  const onCopyFormHandler = (id: string) => {
    copyForm(parseInt(id));
  };

  const onChangeHandler = (
    value: string,
    type: 'entity' | 'resource' | 'question' | 'answer' = 'entity',
    keyId: string,
    answerFormId?: string
  ) => {
    if (type === 'entity') {
      handleEntityChange(keyId, value);
    } else {
      let resource: ResourceEntity | undefined;
      if (type === 'resource') {
        const keyResId = taskContent[keyId as keyof TaskContentEntity];
        setResources((prev) =>
          prev.map((item: ResourceEntity) =>
            item?.id === keyResId ? { ...item, value } : { ...item }
          )
        );
        resource = resources.find(
          (item: ResourceEntity) => item?.id === keyResId
        );
      } else if (type === 'question') {
        const form: TaskContentForm = taskContent.forms?.find(
          (item: TaskContentForm) => item.id === keyId
        );
        const { questionResId } = form;
        setResources((prev) =>
          prev.map((item: ResourceEntity) =>
            item?.id === questionResId ? { ...item, value } : { ...item }
          )
        );
        resource = resources.find(
          (item: ResourceEntity) => item.id === questionResId
        );
      } else {
        setResources((prev) =>
          prev.map((item: ResourceEntity) =>
            item?.id === keyId ? { ...item, value } : { ...item }
          )
        );
        resource = resources.find((item: ResourceEntity) => item.id === keyId);

        // Currently, we saved answer inside form description, so we need this code for consistency only
        // Later need to remove this description and get/set answer directly to resources
        const form: TaskContentForm = taskContent.forms?.find(
          (item: TaskContentForm) => item?.id === answerFormId
        );
        if (form) {
          const answer = form.answers?.find(
            (item: TaskContentAnswer) => item.answerResId === keyId
          );
          if (answer) {
            answer.description = value;
            UpdateTaskContentAsync(
              state.gameDocument!,
              taskContent.id,
              taskContent
            ).then((response) =>
              setState((prev) => UpdateGameDocState(prev, response))
            );
          }
        }
      }
      if (resource) {
        resource.value = value;
        UpdateResourceAsync(state.gameDocument!, resource.id, resource).then(
          (response) => setState((prev) => UpdateGameDocState(prev, response))
        );
      }
    }
  };

  const onAddMedia = (form: TaskContentForm) => {
    setFileUpload('Media');
    setExtensionUploadedFile('image/*, video/*, audio/*');
    setEntityEditorIsVisible(true);
    setTaskContentForm(form);
  };

  const toggleEntityEditor = () =>
    setEntityEditorIsVisible(!entityEditorIsVisible);

  const onMediaEditHandler = (form: TaskContentForm, mediaIndex: number) => {
    setTaskContentForm(form);
    setEditMediaResourceIndex(mediaIndex);
    setIsEditMedia(true);
    toggleEntityEditor();
  };

  const handleEntityWindowEditorSubmit = (
    uploadedImage: UploadedImage,
    target: FileUpload
  ) => {
    //append into resources
    const resourceId = uuid();
    if (target === 'Media') {
      let newTaskContent: TaskContentEntity = { ...taskContent };
      const index = newTaskContent?.forms?.findIndex(
        (x) => x.id === taskContentForm?.id
      );

      const resourceType = uploadedImage?.mimeType?.split('/')[0];

      if (!uploadedImage.resource) {
        AddResourceAsync(
          state.gameDocument!,
          uploadedImage?.fileName,
          resourceId,
          resourceType,
          uploadedImage.blobUrl,
          resourceId,
          uploadedImage.size
        ).then((response) =>
          setState((prev) => UpdateGameDocState(prev, response))
        );
      }

      if (!isEditMedia) {
        if (!newTaskContent.forms![index!].medias) {
          newTaskContent.forms![index!].medias = [];
        }

        newTaskContent.forms![index!].medias?.push(
          uploadedImage.resource ? uploadedImage.resource.id! : resourceId
        );

        UpdateTaskContentAsync(
          state.gameDocument!,
          entity.id,
          newTaskContent
        ).then((response) => {
          setState((prev) => UpdateGameDocState(prev, response!));
        });
      } else {
        newTaskContent!.forms![index!].medias[editMediaResourceIndex] =
          uploadedImage.resource ? uploadedImage.resource.id! : resourceId;
        UpdateTaskContentAsync(
          state.gameDocument!,
          entity.id,
          newTaskContent
        ).then((response) => {
          setState((prev) => UpdateGameDocState(prev, response!));
        });
        setIsEditMedia(false);
        setEditMediaResourceIndex(undefined);
      }
    }

    if (entityEditorIsVisible) {
      setEntityEditorIsVisible(false);
    }
  };

  useEffect(() => {
    if (entity) {
      setTaskContent(cloneDeep({ ...entity }));
      const titleRes: ResourceEntity = state.gameDocument?.resources.find(
        (item) => item.id === entity.titleResId
      )!;
      const contentRes: ResourceEntity = state.gameDocument?.resources.find(
        (item) => item.id === entity.contentResId
      )!;
      const preMessageRes: ResourceEntity = state.gameDocument?.resources.find(
        (item) => item.id === entity.preMessageResId
      )!;
      const taskContentForm: TaskContentForm[] =
        state.gameDocument?.assets.taskContents?.find(
          (item) => item.id === entity.id
        )?.forms ?? [];
      const questionsRes: ResourceEntity[] = [];
      const answersRes: ResourceEntity[] = [];
      taskContentForm.forEach((item: TaskContentForm) => {
        const questionRes: ResourceEntity | undefined =
          state.gameDocument?.resources.find(
            (resource) => resource.id === item.questionResId
          );
        if (questionRes) questionsRes.push(questionRes);
        item.answers?.forEach((answer: TaskContentAnswer) => {
          const answerRes: ResourceEntity | undefined =
            state.gameDocument?.resources.find(
              (resource) => resource.id === answer.answerResId
            );
          if (answerRes) answersRes.push(answerRes);
        });
      });
      setResources(
        [titleRes, contentRes, preMessageRes]
          .concat(questionsRes)
          .concat(answersRes)
      );
    }
  }, [state, entity]);

  return (
    <>
      <Row className={'mt-3'}>
        <Tabs
          defaultActiveKey={'information'}
          id={'timer-tabs'}
          className={'mb-3'}
          mountOnEnter>
          <Tab eventKey={'information'} title={'Information'}>
            <div className={'gap-2'}>
              <Row>
                <Col sm={'6'} md={'6'} lg={'3'} xl={'3'}>
                  <StandardInput
                    name={'name'}
                    label={'Task content name'}
                    value={entity?.name}
                    onChange={(e) => {
                      onChangeHandler(
                        e.target.value as string,
                        'entity',
                        e.target.name!
                      );
                    }}
                    labelStyle={'fw-normal'}
                    validationMessage={
                      requiredFields?.find(
                        (item: RequiredFields<TaskContentEntity>) =>
                          item.name === 'name'
                      )?.errorMessage ?? ''
                    }
                  />
                </Col>
                <Col sm={'6'} md={'6'} lg={'3'} xl={'3'}>
                  <StandardInput
                    name={'titleResId'}
                    label={'Task content title'}
                    labelStyle={'fw-normal'}
                    value={
                      resources.find(
                        (item) => item.id === taskContent.titleResId
                      )?.value ?? ''
                    }
                    onChange={(e) => {
                      onChangeHandler(
                        e.target.value as string,
                        'resource',
                        'titleResId'
                      );
                    }}
                  />
                </Col>
              </Row>
              <Row>
                <Col sm={'12'} md={'12'} lg={'6'} xl={'6'}>
                  <StandardTextarea
                    name={'description'}
                    label={'Task content description'}
                    labelStyle={'fw-normal'}
                    rows={10}
                    value={entity?.description}
                    onChange={(e) => {
                      onChangeHandler(
                        e.target.value as string,
                        'entity',
                        e.target.name!
                      );
                    }}
                  />
                </Col>
              </Row>
              <Row>
                <Col sm={'12'} md={'12'} lg={'6'} xl={'6'}>
                  <ComboboxInput
                    label={'Body type'}
                    value={entity?.bodyType}
                    name={'bodyType'}
                    labelStyle={'fw-normal'}
                    clearButton={false}
                    onChange={(e) =>
                      onChangeHandler(
                        e.target.value as string,
                        'entity',
                        e.target.name!
                      )
                    }
                    data={['HTML', 'Markdown']}
                  />
                </Col>
              </Row>
            </div>
          </Tab>

          {taskContent.bodyType === 'HTML' && (
            <Tab eventKey={'PreMessage'} title={'Pre Message'}>
              <Row>
                <Col sm={'8'} md={'8'} lg={'8'} xl={'8'}>
                  <HtmlEditor
                    value={
                      resources.find((item) => {
                        const resId = taskContent.preMessageResId;
                        return item?.id === resId;
                      })?.value ?? ''
                    }
                    handleHtmlChange={(e) => {
                      onChangeHandler(e.html, 'resource', 'preMessageResId');
                    }}
                  />
                </Col>
              </Row>
            </Tab>
          )}
          {taskContent.bodyType === 'HTML' && (
            <Tab eventKey={'Intro'} title={'Intro'}>
              <Row>
                <Col sm={'8'} md={'8'} lg={'8'} xl={'8'}>
                  <HtmlEditor
                    value={
                      resources.find(
                        (item) => item?.id === taskContent?.contentResId
                      )?.value ?? ''
                    }
                    handleHtmlChange={(e) => {
                      onChangeHandler(e.html, 'resource', 'contentResId');
                    }}
                  />
                </Col>
              </Row>
            </Tab>
          )}

          {taskContent.bodyType === 'Markdown' && (
            <Tab eventKey={'Markdown'} title={'Markdown'}>
              <Row>
                <Col sm={'8'} md={'8'} lg={'8'} xl={'8'}>
                  <MarkdownEditor
                    value={
                      resources.find(
                        (item) => item.id === taskContent.contentResId
                      )?.value ?? ''
                    }
                    handleMarkdownChange={(e) => {
                      onChangeHandler(
                        // @ts-ignore expect mismatched types.
                        defaultMarkdownSerializer.serialize(e.value),
                        'resource',
                        'contentResId'
                      );
                    }}
                  />
                </Col>
              </Row>
            </Tab>
          )}

          <Tab eventKey={'Form'} title={'Forms'}>
            {forms.map((form: TaskContentForm, index: number) => {
              return (
                <div className={'card p-3 mt-3'} key={form.id}>
                  <Questionnaire
                    id={index.toString()}
                    score={form.score}
                    answerType={form.type}
                    isManualScoring={form?.isManualScoring}
                    manualScoring={form?.manualScoring}
                    isScoringReduction={form?.isScoringReduction}
                    scoringReduction={form?.scoringReduction}
                    maxAttempts={form?.maxAttempts}
                    pointDepreciation={form?.pointDepreciation}
                    answers={getAnswersDescription(form)}
                    customFeedback={form?.customFeedback}
                    taskContentId={taskContent.id}
                    onChangeAnswerType={onChangeAnswerTypeHandler}
                    onChangeAnswers={(answers) =>
                      onChangeAnswersHandler(form.id, answers)
                    }
                    question={
                      resources.find((x) => x?.id === form.questionResId)
                        ?.value ?? ''
                    }
                    onAddAnswer={onAddAnswerHandler}
                    onDeleteOption={onDeleteOptionHandler}
                    onCopyForm={onCopyFormHandler}
                    onDeleteForm={onDeleteFormHandler}
                    onChangeScoring={(
                      score,
                      isManualScoring,
                      manualScoring,
                      maxAttempts,
                      pointDepreciation,
                      isScoringReduction,
                      scoringReduction,
                      answersWithScoring
                    ) =>
                      onChangeScoringHandler(
                        form.id,
                        score,
                        isManualScoring,
                        manualScoring,
                        maxAttempts,
                        pointDepreciation,
                        isScoringReduction,
                        scoringReduction,
                        answersWithScoring
                      )
                    }
                    handleInputChange={(
                      value: string,
                      type: 'question' | 'answer',
                      answerResId?: string
                    ) => {
                      if (type === 'question') {
                        onChangeHandler(value, type, form.id);
                      } else {
                        onChangeHandler(value, type, answerResId!, form.id);
                      }
                    }}
                    onCustomFeedbackChange={(val) => {
                      onCustomFeedbackHandler(form.id, val!);
                    }}
                    onAddMedia={() => onAddMedia(form)}
                    taskContentForm={form}
                    onDeleteMedia={(mediaIndex) =>
                      onDeleteMediaHandler(form.id, mediaIndex)
                    }
                    onEditMedia={(mediaIndex) =>
                      onMediaEditHandler(form, mediaIndex)
                    }
                  />
                </div>
              );
            })}

            <Row>
              <Col>
                <div
                  className={'card cursor-pointer p-3 mt-3 bg-base-bg'}
                  onClick={onAddNewForm}>
                  <div className={'card-body d-flex justify-content-center'}>
                    <span className={'material-symbols-outlined'}>dns</span>
                    <Label className={'cursor-pointer'}>
                      {forms.length === 0 ? 'Add form' : 'Add question'}
                    </Label>
                  </div>
                </div>
              </Col>
            </Row>
          </Tab>
        </Tabs>
      </Row>
      {entityEditorIsVisible ? (
        <ResourceWindow
          toggleDialog={toggleEntityEditor}
          onSubmit={(uploadedFile) =>
            handleEntityWindowEditorSubmit(uploadedFile, 'Media')
          }
          acceptedExtension={extensionUploadedFile}
          imageUrl={selectedImageUrl}
          imageSource={
            fileUpload === 'Media'
              ? 'Game Design | Assets - Task Content - Form'
              : undefined
          }
        />
      ) : null}
    </>
  );
};
