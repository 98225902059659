import {
  Checkbox,
  CheckboxChangeEvent,
  NumericTextBox
} from '@progress/kendo-react-inputs';
import { Error } from '@progress/kendo-react-labels';
import { Col, Row } from 'react-bootstrap';
import { ScoringReductionEntity } from '../../types/game-document/entities/task-content';
import DurationPicker from '../forms/duration-picker';

type ScoringReductionProps = {
  isDisabled: boolean;
  isScoringReduction?: boolean;
  scoringReductionState?: ScoringReductionEntity;
  errorMessage?: string;
  isElapsedPointReductionEnabled: boolean;
  onChangeScoringReductionCheckbox: () => void;
  onChangeElapsedPointReductionEnabledCheckbox: (
    isElapsedPointReductionEnabled: boolean
  ) => void;
  onChangeScoringReductionInput: (
    scoringReductionState?: ScoringReductionEntity
  ) => void;
};
export function ScoringReductionAnswer({
  isDisabled,
  isScoringReduction,
  scoringReductionState,
  isElapsedPointReductionEnabled,
  errorMessage,
  onChangeScoringReductionCheckbox = () => {},
  onChangeScoringReductionInput = () => {},
  onChangeElapsedPointReductionEnabledCheckbox = () => {}
}: ScoringReductionProps) {
  const onChangeElapsedPointReductionEnabled = (e: CheckboxChangeEvent) => {
    let newScoringReductionState: ScoringReductionEntity = {
      ...scoringReductionState,
      scoreReduction: !e.value ? undefined : 0,
      scoreReductionInterval: !e.value ? undefined : 0
    };

    onChangeScoringReductionInput(newScoringReductionState);
    onChangeElapsedPointReductionEnabledCheckbox(e.value);
  };

  const onChangeScoringReduction = (
    field?: keyof ScoringReductionEntity,
    value?: number
  ) => {
    let newScoringReductionState: ScoringReductionEntity = {
      ...scoringReductionState,
      [`${field}`]: value
    };
    onChangeScoringReductionInput(newScoringReductionState);
  };

  return (
    <>
      <Row className={'d-flex justify-content-between'}>
        <Col md={'6'}></Col>
        <Col sm={'12'} md={'6'} lg={'6'} xl={'6'}>
          <Checkbox
            id={'checkbox-scoring-reduction'}
            disabled={isDisabled}
            label={'Time Limit'}
            checked={isScoringReduction}
            onChange={onChangeScoringReductionCheckbox}></Checkbox>
        </Col>
      </Row>
      {isScoringReduction && (
        <Row>
          <Col md={'6'}></Col>
          <Col sm={12} md={6} lg={6} xl={6}>
            <Row className={'d-flex align-items-center mt-2 p-1'}>
              <Col sm={'12'} md={'12'} lg={'12'} xl={'12'}>
                <Row>
                  <Col sm={'6'} md={'6'} lg={'6'} xl={'6'}>
                    <span className={'mr-2'}>Time Limit</span>
                  </Col>
                  <Col sm={'6'} md={'6'} lg={'6'} xl={'6'}>
                    <DurationPicker
                      value={
                        (scoringReductionState?.maximumTimeLimit ?? 0) as number
                      }
                      onChange={(newSecond) =>
                        onChangeScoringReduction('maximumTimeLimit', newSecond)
                      }
                      className={'mr-2'}
                      disabled={isDisabled}
                      width={170}
                      format="HH:mm:ss"
                    />
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row>
              <Col className={'ml-1'}>
                {!scoringReductionState?.maximumTimeLimit && errorMessage && (
                  <Error>{errorMessage}</Error>
                )}
              </Col>
            </Row>
            <></>
            <Row className={'d-flex justify-content-between'}>
              <Col sm={'12'} md={'6'} lg={'6'} xl={'6'}>
                <Checkbox
                  id={'checkbox-scoring-reduction'}
                  disabled={isDisabled}
                  label={'Point reduction after elapsed'}
                  checked={isElapsedPointReductionEnabled}
                  onChange={onChangeElapsedPointReductionEnabled}></Checkbox>
              </Col>
            </Row>
            {isElapsedPointReductionEnabled && (
              <>
                <Row className={'d-flex align-items-center mt-2 p-1'}>
                  <Col sm={'12'} md={'12'} lg={'12'} xl={'12'}>
                    <Row>
                      <Col sm={'6'} md={'6'} lg={'6'} xl={'6'}>
                        <span className={'mr-2'}>Point Depreciation</span>
                      </Col>
                      <Col sm={'6'} md={'6'} lg={'6'} xl={'6'}>
                        <NumericTextBox
                          className={'mr-2'}
                          value={
                            (scoringReductionState?.scoreReduction ??
                              0) as number
                          }
                          width={170}
                          min={0}
                          disabled={!isElapsedPointReductionEnabled}
                          onChange={(e) =>
                            onChangeScoringReduction(
                              'scoreReduction',
                              e.target.value as number
                            )
                          }
                        />
                      </Col>
                    </Row>
                  </Col>
                </Row>
                <Row>
                  <Col className={'ml-1'}>
                    {isElapsedPointReductionEnabled &&
                      !scoringReductionState?.scoreReduction &&
                      scoringReductionState?.scoreReduction === 0 &&
                      errorMessage && <Error>{errorMessage}</Error>}
                  </Col>
                </Row>
                <Row className={'d-flex align-items-center mt-2 p-1'}>
                  <Col sm={'12'} md={'12'} lg={'12'} xl={'12'}>
                    <Row>
                      <Col sm={'6'} md={'6'} lg={'6'} xl={'6'}>
                        <span className={'mr-2'}>Time Increments</span>
                      </Col>
                      <Col sm={'6'} md={'6'} lg={'6'} xl={'6'}>
                        <DurationPicker
                          value={
                            (scoringReductionState?.scoreReductionInterval ??
                              0) as number
                          }
                          onChange={(newSecond) =>
                            onChangeScoringReduction(
                              'scoreReductionInterval',
                              newSecond
                            )
                          }
                          disabled={!isElapsedPointReductionEnabled}
                          className={'mr-2'}
                          width={170}
                          format="HH:mm:ss"
                        />
                      </Col>
                    </Row>
                  </Col>
                </Row>
                <Row>
                  <Col className={'ml-1'}>
                    {isElapsedPointReductionEnabled &&
                      !scoringReductionState?.scoreReductionInterval &&
                      errorMessage && <Error>{errorMessage}</Error>}
                  </Col>
                </Row>
              </>
            )}
          </Col>
        </Row>
      )}
    </>
  );
}
