import React, { useState } from 'react';
import {
  TimePicker,
  TimePickerChangeEvent,
  TimePickerProps
} from '@progress/kendo-react-dateinputs';

interface DurationPickerProps
  extends Omit<TimePickerProps, 'value' | 'onChange'> {
  value: number;
  onChange: (newSecond: number) => void;
}

const DurationPicker: React.FC<DurationPickerProps> = ({
  value,
  onChange,
  ...timePickerProps
}) => {
  const handleChange = (event: TimePickerChangeEvent) => {
    const time = event.value as Date;
    const newSeconds =
      time.getHours() * 3600 + time.getMinutes() * 60 + time.getSeconds();
    onChange(newSeconds);
  };

  return (
    <>
      <TimePicker
        value={
          new Date(
            0,
            0,
            0,
            Math.floor(value / 3600),
            Math.floor((value % 3600) / 60),
            value % 60
          )
        }
        onChange={handleChange}
        {...timePickerProps}
      />
    </>
  );
};

export default DurationPicker;
