import cloneDeep from 'lodash.clonedeep';
import { Col, Row } from 'react-bootstrap';
import React, { useContext, useEffect, useState } from 'react';

import Video from '../video';
import {
  DefaultCustomFeedback,
  GetTaskContentById,
  UpdateGameDocState,
  UpdateTaskContentAsync
} from '../../utils/game-document';
import {
  AddResourceAsync,
  DeleteResourceAsync,
  GetResourceById
} from '../../utils/game-document/resources';
import { TextareaInput } from '../form-input';
import { Answer, AnswerProps } from './answer';
import { uuid } from '../../types/common-helper';
import YesNoDialog from '../dialogs/yes-no-dialog';
import { Label } from '@progress/kendo-react-labels';
import { Checkbox } from '@progress/kendo-react-inputs';
import {
  FormCustomFeedback,
  TaskContentForm
} from '../../types/game-document/entities/task-content';
import { GameDocument } from '../../types/game-document';
import { YesNoDialogResult } from '../../types/dialog-result';
import PopupMenu, { popupMenu as MenuList } from '../popupMenu';
import { GameDocumentContext } from '../../contexts/game-document';
import MediaPreviewDialog from '../dialogs/media-preview-dialog';

export interface QuestionnaireContentProps extends AnswerProps {
  question?: string;
  taskContentForm?: TaskContentForm;
  taskContentId: string;
  onDeleteForm?: (id: string) => void;
  onCopyForm?: (id: string) => void;
  onShowScoring?: (id: string) => void;
  onShowCustomFeedback?: (id: string, answerType?: string) => void;
  handleInputChange?: (
    value: string,
    type: 'question' | 'answer',
    answerResId?: string
  ) => void;
  onAddMedia?: () => void;
  onDeleteMedia?: (mediaIndex: number) => void;
  onEditMedia?: (mediaIndex: number) => void;
}
export function QuestionnaireContent({
  question,
  taskContentForm,
  taskContentId,
  onDeleteForm = () => {},
  onCopyForm = () => {},
  onShowScoring = () => {},
  handleInputChange = () => {},
  onShowCustomFeedback = () => {},
  onAddMedia = () => {},
  onDeleteMedia = () => {},
  onEditMedia = () => {},
  ...props
}: QuestionnaireContentProps) {
  const [state, setState] = useContext(GameDocumentContext);
  const [menuList, setMenuList] = useState<MenuList[]>([]);
  const [deleteMediaResourceIndex, setDeleteMediaResourceIndex] = useState<
    number | undefined
  >(undefined);
  const [showDeleteMediaConfirm, setShowDeleteMediaConfirm] =
    useState<boolean>(false);
  const [previewItem, setPreviewItem] = useState<string>('');
  const [previewItemType, setPreviewItemType] = useState<string>('');

  const onConfirmDeleteMedia = (result: YesNoDialogResult) => {
    if (result === 'yes') {
      onDeleteMedia(deleteMediaResourceIndex!);
    }

    setShowDeleteMediaConfirm(false);
  };

  const generateMediaPopupMenu = () => {
    let newMenus: MenuList[] = [
      { classIcon: 'edit', textMenu: 'Edit', textClass: '' },
      { classIcon: 'delete', textMenu: 'Delete', textClass: 'text-danger' }
    ];
    setMenuList(newMenus);
  };
  const onDeleteFormHandler = () => {
    if (props.id !== '') {
      onDeleteForm(props.id!);
    }
  };

  const onCopyFormHandler = () => {
    if (props.id !== '') {
      onCopyForm(props.id!);
    }
  };

  const onShowScoringHandler = () => {
    if (props.id !== '') {
      onShowScoring(props.id!);
    }
  };

  const onMenuSelected = (id: number, menu: MenuList) => {
    if (menu.textMenu === 'Delete') {
      setDeleteMediaResourceIndex(id!);
      setShowDeleteMediaConfirm(true);
    }
    if (menu.textMenu === 'Edit') {
      onEditMedia(id);
    }
  };

  useEffect(() => {
    generateMediaPopupMenu();
    setPreviewItem('');
    setPreviewItemType('');
  }, [taskContentForm?.medias]);

  const handleEnableFeedbackChange = React.useCallback(
    async (enable: boolean) => {
      if (state.gameDocument) {
        const updateGameDocument = async (
          updatedGameDocument: GameDocument,
          defaultFeedback?: FormCustomFeedback
        ) => {
          const taskContent = GetTaskContentById(
            updatedGameDocument,
            taskContentId
          );
          const newTaskContentForm = {
            ...cloneDeep(taskContentForm),
            ...(enable &&
              defaultFeedback && { customFeedback: defaultFeedback })
          };

          if (!enable) delete newTaskContentForm.customFeedback;

          const taskContentFormIndex = taskContent?.forms?.findIndex(
            (form) => form.id === newTaskContentForm.id
          );

          if (
            taskContent &&
            taskContent.forms &&
            taskContentFormIndex !== undefined &&
            taskContentFormIndex !== -1
          ) {
            taskContent.forms[taskContentFormIndex] = newTaskContentForm;

            updatedGameDocument = await UpdateTaskContentAsync(
              updatedGameDocument,
              taskContentId,
              taskContent
            );
            setState((prev) => UpdateGameDocState(prev, updatedGameDocument));
          }

          return updatedGameDocument;
        };

        if (enable) {
          const isCheckboxForm = props.answerType === 'checkbox';
          const correctMessageFeedback = {
            isDefaultAllCorrect: true,
            allCorrectMessage: 'Congratulations, you got it!',
            allCorrectUrlResId: '',
            allCorrectMessageResId: uuid()
          };
          const wrongMessageFeedback = {
            isDefaultAllWrong: true,
            allWrongMessage: "That's not the answer!",
            allWrongUrlResId: '',
            allWrongMessageResId: uuid()
          };
          const partialMessageFeedback = {
            isDefaultPartiallyCorrect: true,
            partiallyCorrectMessage:
              'That answer was partially correct! Partial points awarded.',
            partiallyCorrectUrlResId: '',
            partiallyCorrectMessageResId: uuid()
          };

          const defaultFeedback = {
            ...DefaultCustomFeedback(),
            ...correctMessageFeedback,
            ...wrongMessageFeedback,
            ...(isCheckboxForm && { ...partialMessageFeedback })
          };

          let updatedGameDocument = state.gameDocument;
          //add to resource
          updatedGameDocument = await AddResourceAsync(
            state.gameDocument,
            'All correct feedback message default',
            '',
            'text',
            correctMessageFeedback.allCorrectMessage,
            correctMessageFeedback.allCorrectMessageResId
          );
          updatedGameDocument = await AddResourceAsync(
            updatedGameDocument,
            'All wrong feedback message default',
            '',
            'text',
            wrongMessageFeedback.allWrongMessage,
            wrongMessageFeedback.allWrongMessageResId
          );
          if (isCheckboxForm) {
            updatedGameDocument = await AddResourceAsync(
              updatedGameDocument,
              'Partial correct feedback message default',
              '',
              'text',
              partialMessageFeedback.partiallyCorrectMessage,
              partialMessageFeedback.partiallyCorrectMessageResId
            );
          }

          updateGameDocument(updatedGameDocument, defaultFeedback);
        } else {
          if (taskContentForm?.customFeedback) {
            const customFeedback = cloneDeep(taskContentForm?.customFeedback);
            let updatedGameDocument = await updateGameDocument(
              state.gameDocument
            );

            //remove from resource
            const {
              allCorrectMessageResId,
              allWrongMessageResId,
              partiallyCorrectMessageResId
            } = customFeedback;
            if (allCorrectMessageResId)
              updatedGameDocument = await DeleteResourceAsync(
                updatedGameDocument,
                allCorrectMessageResId
              );
            if (allWrongMessageResId)
              updatedGameDocument = await DeleteResourceAsync(
                updatedGameDocument,
                allWrongMessageResId
              );
            if (partiallyCorrectMessageResId)
              updatedGameDocument = await DeleteResourceAsync(
                updatedGameDocument,
                partiallyCorrectMessageResId
              );

            setState((prev) => UpdateGameDocState(prev, updatedGameDocument));
          }
        }
      }
    },
    [state.gameDocument, props.answerType, taskContentId, taskContentForm]
  );

  return (
    <>
      <Row>
        <Col md={'7'}>
          <TextareaInput
            label="Question"
            value={question}
            onChange={(e) =>
              handleInputChange(e.target.value as string, 'question')
            }
            rows={1}
            autoSize
          />
        </Col>
        <Col md={'5'}>
          <Answer handleInputChange={handleInputChange} {...props} />
        </Col>
      </Row>
      <Row className={'mt-2'}>
        <Col xs={12}>
          <Label>Media</Label>
        </Col>
        <div className={'thumb-nails d-flex flex-wrap mt-2'}>
          {taskContentForm?.medias?.map((resourceId, index: number) => {
            let resource = GetResourceById(state?.gameDocument!, resourceId);

            return (
              <>
                <div className={'d-flex'} key={resourceId}>
                  <div
                    className={
                      'w-8 h-8 bg-light position-relative rounded d-flex overflow-hidden flex-column justify-content-center align-items-center cursor-pointer'
                    }
                    onClick={() => {
                      if (resource && resource.value && resource.type) {
                        setPreviewItem(resource?.value as string);
                        setPreviewItemType(resource?.type as string);
                      }
                    }}>
                    {resource &&
                      (resource.type === 'video' ||
                        resource.type === 'audio') && (
                        <div
                          className={
                            'mask position-absolute top-0 end-0 h-full w-full d-flex align-items-center justify-content-center'
                          }
                          style={{
                            backgroundImage: `url(${resource.value})`,
                            backgroundPosition: 'center'
                          }}>
                          <button className={'btn text-light'}>
                            <span className={'material-symbols-outlined'}>
                              {resource.type === 'audio'
                                ? 'music_note'
                                : 'play_arrow'}
                            </span>
                          </button>
                        </div>
                      )}
                    {resource && resource.type === 'image' && (
                      <img
                        src={resource?.value}
                        className={'rounded'}
                        alt={resource?.name ?? ''}
                      />
                    )}
                  </div>
                  <PopupMenu
                    menus={menuList}
                    id={index}
                    onMenuSelected={(id, menu) => {
                      onMenuSelected(id, menu);
                    }}
                    idString={index.toString()}
                  />
                </div>
              </>
            );
          })}

          <div
            className={
              'w-8 h-8 border border-1 bg-light rounded d-flex flex-column justify-content-center align-items-center cursor-pointer text-decoration-none'
            }
            onClick={onAddMedia}>
            <span className={'material-symbols-outlined text-primary'}>
              add_photo_alternate
            </span>
            Add
          </div>
        </div>
      </Row>
      <Row>
        <Col md={'12'} className={'d-flex align-items-center mt-3'}>
          <div className={`d-flex text-primary p-2`}>
            <span
              className={'d-flex'}
              role={'button'}
              onClick={onShowScoringHandler}>
              <span className={'material-symbols-outlined mr-3'}>
                check_circle
              </span>
              <span>Scoring</span>
            </span>
          </div>

          <div className={'d-flex flex-grow-1 text-primary p-2'}>
            {props.answerType !== 'textarea' && props.answerType !== 'none' && (
              <>
                <div className={'d-flex align-items-center gap-2'}>
                  <Checkbox
                    name={'checkbox-use-default'}
                    label={'Enable feedback'}
                    value={Boolean(taskContentForm?.customFeedback)}
                    onChange={(e) =>
                      handleEnableFeedbackChange(Boolean(e.target.value))
                    }
                  />
                  {Boolean(taskContentForm?.customFeedback) && (
                    <span
                      className={
                        'material-symbols-outlined cursor-pointer text-decoration-none mr-3'
                      }
                      onClick={() =>
                        onShowCustomFeedback(
                          props.id!,
                          props.answerType === 'checkbox' ? 'checkbox' : ''
                        )
                      }>
                      edit
                    </span>
                  )}
                </div>
              </>
            )}
          </div>

          <span
            className={'material-symbols-outlined cursor-pointer p-2'}
            onClick={onCopyFormHandler}>
            content_copy
          </span>
          <span
            className={
              'material-symbols-outlined cursor-pointer text-danger p-2'
            }
            onClick={onDeleteFormHandler}>
            delete
          </span>
        </Col>
      </Row>

      {previewItem && previewItemType && (
        <MediaPreviewDialog
          title="Media Preview"
          mediaType={previewItemType}
          mediaUrl={previewItem}
          onClose={() => {
            setPreviewItem('');
            setPreviewItemType('');
          }}
        />
      )}

      {showDeleteMediaConfirm && (
        <YesNoDialog
          title={'Confirm removal'}
          onConfirm={onConfirmDeleteMedia}
          onClose={() => setShowDeleteMediaConfirm(false)}>
          Are you sure you want to remove the image?
        </YesNoDialog>
      )}
    </>
  );
}
