import React, { useCallback, useContext, useEffect, useState } from 'react';
import { GameDocumentContext } from '../../contexts/game-document';
import { StandardInput } from '../../components/forms';
import {
  AutoCompleteChangeEvent,
  AutoCompleteCloseEvent,
  DropDownList,
  DropDownListChangeEvent
} from '@progress/kendo-react-dropdowns';
import { PanelBar, PanelBarItem } from '@progress/kendo-react-layout';
import {
  Checkbox,
  Input,
  InputChangeEvent,
  RadioButtonChangeEvent
} from '@progress/kendo-react-inputs';
import { Button } from '@progress/kendo-react-buttons';
import { Coordinate, Place } from '../../types/place';
import { GetGeolocationAsync, SearchPlaceAsync } from '../../services/maps';
import YesNoDialog from '../dialogs/yes-no-dialog';
import { MapContext } from '../../components/map/map-context';
import { Boundary, GameDocument, MapImage } from '../../types/game-document';
import {
  AreaEntity,
  EntityEditor,
  MapEntity,
  ResourceEntity,
  TaskEntity,
  TimerEntity,
  ZoneEntity
} from '../../types/game-document/';
import { useNavigate, useParams } from 'react-router-dom';
import PopupMenu, { popupMenu as MenuList } from '../../components/popupMenu';
import WorldMapEvent from '../../pages/world-map/world-map-event';
import { Algorithm } from '../../types/algorithm';
import {
  AddResourceAsync,
  DeleteResourceAsync,
  GetResourceEntity,
  GetResourceKeys,
  GetResourceValue,
  UpdateGameDocState,
  UpdateMapAsync,
  UpdateResourceAsync
} from '../../utils/game-document';
import {
  CopyTaskAsync,
  DeleteAreaAsync,
  DeleteZoneAsync,
  GetAreasByZoneId,
  GetAreasWorldMap,
  GetAssetRoutesByMapId,
  GetDetailRoutesById,
  GetDetailRoutesByMapId,
  GetDetailRoutesByRouteId,
  GetMapByIdAsync,
  GetTasksByIds,
  GetTasksByZoneId,
  GetZoneById,
  RemoveRoutesAsync,
  RemoveTaskBoundaryAsync,
  SaveEntityEditorAsync,
  UpdateAreaAsync,
  UpdateRoutesAsync,
  UpdateTaskAsync,
  UpdateZoneAsync
} from '../../utils/game-document/assets/index';
import { uuid } from '../../types/common-helper';
import MapSettings from './map-settings';
import { fromLonLat, fromUserCoordinate, toLonLat } from 'ol/proj';
import { TaskEditorWindow } from '../../features/game-document/tasks';
import { AreaEditorWindow } from '../../features/game-document/areas';
import { YesNoDialogResult } from '../../types/dialog-result';
import { ZoneAssetId } from '../../types/game-document/entities/zone';
import {
  ResourceWindow,
  UploadedImage
} from '../../features/game-document/image-resource/resource-window';
import { TaskRoute } from '../../types/game-document/entities/routes';
import { RouteEditorWindow } from '../../features/game-document/maps/map-routes-window';
import {
  GetAssetMapImagesByMapId,
  GetDetailMapImageByMapImageId,
  RemoveMapImageAsync,
  UpdateMapImagesAsync
} from '../../utils/game-document/assets/map-images';
import { MapImageWindow } from '../../features/game-document/maps/map-images-window';
import { useGameDocumentResources } from '../../hooks/use-game-document-resources';

type MapsOptions =
  | 'GPS Map'
  | 'Illustration Map'
  | 'GPS + Illustration Overlay';
type MapAction = 'Zone' | 'Task' | 'Area' | 'Annotation' | 'Images' | '';

interface MapEditorProps {
  zoneId?: string;
  mapInfo?: MapEntity;
  zones?: ZoneEntity[];
  mapOptions?: MapsOptions;
  showMapAccordion?: boolean;
  showZonesAccordion?: boolean;
  showAreasAccordion?: boolean;
  showTasksAccordion?: boolean;
  currentImageResource?: string;
  selectedAllObjectType?: string;
  removeTaskOverlay: (id: string) => void;
  onSelectedPanel?: (e: MapAction) => void;
  onChangeMapOptions?: (e: MapsOptions) => void;
  onSelectAllAssets?: (selected: string) => void;
  onCenterMap?: (e: React.MouseEvent<HTMLButtonElement>) => void;
  updateTaskOverlayPosition: (
    id: string,
    newPosition: Coordinate | number[]
  ) => void;
}

const MapEditor = ({
  zones,
  mapInfo,
  mapOptions,
  zoneId = '',
  onCenterMap,
  onSelectedPanel,
  showMapAccordion,
  onChangeMapOptions,
  showZonesAccordion,
  showTasksAccordion,
  showAreasAccordion,
  currentImageResource,
  selectedAllObjectType,
  removeTaskOverlay,
  onSelectAllAssets = () => {},
  updateTaskOverlayPosition
}: MapEditorProps) => {
  const { gameId } = useParams();
  const { taskId } = useParams();

  const [state, setState] = useContext(GameDocumentContext);
  const gameDocumentFiles = useGameDocumentResources();
  const [tasks, setTasks] = useState<TaskEntity[]>([]);
  const [dialogVisible, setDialogVisible] = useState<boolean>(false);
  const [actionsEventDialogVisible, setActionsEventDialogVisible] =
    useState<boolean>(false);
  const [searchResult, setSearchResult] = useState<Place[]>([]);
  let filterTimeout: NodeJS.Timeout | undefined;
  const [zoneName, setZoneName] = useState<string>('');
  const [zoneDescription, setZoneDescription] = useState<string>('');
  const [resources, setResources] = useState<ResourceEntity[]>([]);
  const [showDeleteIllustrationConfirm, setShowDeleteIllustrationConfirm] =
    useState<boolean>(false);
  const [showDeleteTaskConfirm, setShowDeleteTaskConfirm] =
    useState<boolean>(false);
  const map = useContext(MapContext);
  const [titleDialog, setTitleDialog] = useState<string>('');
  const [selectedPanel, setSelectedPanel] = useState<MapAction>('');
  const [searchZoneText, setSearchZoneText] = useState<string>('');
  const [searchAreaText, setSearchAreaText] = useState<string>('');
  const [searchTaskText, setSearchTaskText] = useState<string>('');
  const [searchRouteText, setSearchRouteText] = useState<string>('');
  const [searchImageText, setSearchImageText] = useState<string>('');
  const [isShowTasknameVisibility, setIsShowTasknameVisibility] =
    useState<boolean>(state.gameDocument?.overview?.showTasknameVisibility!);
  const [areas, setAreas] = useState<AreaEntity[]>([]);
  const [selectedAreaId, setSelectedAreaId] = useState<number>(-1);
  const [showDeleteAreaConfirmation, setShowDeleteAreaConfirmation] =
    useState<boolean>(false);
  const [actionsAreaDialogVisible, setActionsAreaDialogVisible] =
    useState<boolean>(false);
  const [actionsRoutesDialogVisible, setActionsRoutesDialogVisible] =
    useState<boolean>(false);
  const [actionsMapImageDialogVisible, setActionsMapImageDialogVisible] =
    useState<boolean>(false);
  const getEntityById = (entityId: string) =>
    state?.gameDocument?.assets?.zones !== undefined
      ? state.gameDocument?.assets.zones?.find((i) => i.id === entityId)
      : undefined;
  const [entity, setEntity] = useState(() => getEntityById(zoneId!));
  const [actionsEditTaskDialogVisible, setActionsEditTaskDialogVisible] =
    useState<boolean>(false);
  const [currentTaskIdForDelete, setCurrentTaskIdForDelete] =
    useState<number>(0);
  const [taskMenu, setTaskMenu] = React.useState<MenuList[]>([]);
  const generateTaskMenu = () => {
    let newMenus: MenuList[] = [
      { classIcon: 'edit', textMenu: 'Edit', textClass: '' },
      { classIcon: 'content_copy', textMenu: 'Copy', textClass: '' },
      { classIcon: 'delete', textMenu: 'Delete', textClass: 'text-danger' }
    ];
    setTaskMenu(newMenus);
  };
  const { gameDocument } = state;
  const [imageResource, setImageResource] = useState<ResourceEntity>({
    id: '',
    name: '',
    description: ''
  });
  const [entityEditorValue, setEntityEditorValue] =
    useState<EntityEditor<TaskEntity>>();
  const [resourceEditorValue, setResourceEditorValue] =
    useState<EntityEditor<ResourceEntity>[]>();
  const [selectedRouteId, setSelectedRouteId] = useState<string>('');
  const [selectedMapImageId, setSelectedMapImageId] = useState<string>('');
  const [showDeleteRouteConfirmation, setShowDeleteRouteConfirmation] =
    useState<boolean>(false);
  const [showDeleteMapImageConfirmation, setShowDeleteMapImageConfirmation] =
    useState<boolean>(false);
  const [ableUploadImage, setAbleUploadImage] = useState<boolean>(false);

  const toggleRouteDeleteDialog = () => {
    setShowDeleteRouteConfirmation(!showDeleteRouteConfirmation);
  };

  const toggleMapImageDeleteDialog = () => {
    setShowDeleteMapImageConfirmation(!showDeleteMapImageConfirmation);
  };

  const [selectedPlace, setSelectedPlace] = useState<Place | undefined>();

  const onConfirmDeleteRoute = (result: YesNoDialogResult) => {
    if (result === 'yes') {
      if (gameDocument) {
        if (state.gameDocument) {
          if (zoneId !== '') {
            const zoneMapAssetId: string =
              state.gameDocument?.rules.worldMap.zones.find(
                (zone: ZoneAssetId) => zone.zoneAssId === zoneId
              )?.mapAssId ?? '';

            GetDetailRoutesById(
              state?.gameDocument!,
              zoneMapAssetId,
              selectedRouteId
            ).then((routeEntity) => {
              if (!routeEntity) {
                return;
              }
              RemoveRoutes(routeEntity as TaskRoute);
            });
          } else {
            GetDetailRoutesByRouteId(
              state?.gameDocument!,
              selectedRouteId
            ).then((routeEntity) => {
              if (!routeEntity) {
                return;
              }
              RemoveRoutes(routeEntity as TaskRoute);
            });
          }
        }
      }
    }
    toggleRouteDeleteDialog();
  };
  const onConfirmDeleteMapImage = (result: YesNoDialogResult) => {
    if (result === 'yes') {
      if (gameDocument) {
        if (state.gameDocument) {
          GetDetailMapImageByMapImageId(
            state?.gameDocument!,
            selectedMapImageId
          ).then((response) => {
            RemoveMapImage(response as MapImage);
          });
        }
      }
    }
    toggleMapImageDeleteDialog();
  };

  const getRoutes = () => {
    if (state.gameDocument) {
      let mapAssetId = '';

      if (zoneId !== '') {
        mapAssetId =
          state.gameDocument?.rules.worldMap.zones.find(
            (zone: ZoneAssetId) => zone.zoneAssId === zoneId
          )?.mapAssId ?? '';
      } else {
        mapAssetId = state?.gameDocument?.rules?.worldMap?.mapAssId!;
      }
      const routes = GetAssetRoutesByMapId(gameDocument!, mapAssetId)!;

      return routes?.filter((x) =>
        x.name?.toLowerCase()?.includes(searchRouteText?.toLowerCase())
      );
    }
    return [];
  };

  const getMapImages = () => {
    if (state.gameDocument) {
      let mapAssetId = '';

      if (zoneId !== '') {
        mapAssetId =
          state.gameDocument?.rules.worldMap.zones.find(
            (zone: ZoneAssetId) => zone.zoneAssId === zoneId
          )?.mapAssId ?? '';
        return GetAssetMapImagesByMapId(gameDocument!, mapAssetId)!;
      } else {
        mapAssetId = state?.gameDocument?.rules?.worldMap?.mapAssId!;
        return GetAssetMapImagesByMapId(gameDocument!, mapAssetId)!;
      }
    }
    return [];
  };

  // #region "Zone"
  const [zoneMenu, setZoneMenu] = React.useState<MenuList[]>([]);

  const [selectedZone, setSelectedZone] = useState<number>(0);
  const [showDeleteZoneConfirmation, setShowDeleteZoneConfirmation] =
    useState<boolean>(false);

  let navigate = useNavigate();

  const generateZoneMenu = () => {
    let menus: MenuList[] = [
      { classIcon: 'edit', textMenu: 'Edit', textClass: '' },
      { classIcon: 'delete', textMenu: 'Delete', textClass: 'text-danger' }
    ];
    setZoneMenu(menus);
  };

  const getZoneByIndex = (index: number) => {
    if (gameDocument) {
      let { zones } = gameDocument?.assets;

      return zones
        ?.filter((zone) =>
          zone.name?.toLowerCase().includes(searchZoneText.toLowerCase())
        )
        .at(index);
    }
  };

  const handleZoneMenuSelected = (id: number, menu: MenuList) => {
    if (menu.textMenu === 'Edit') {
      setSelectedZone(id);

      if (gameDocument) {
        let zone = getZoneByIndex(id);

        navigate(`/designer/${gameId}/map/${zone?.id}`);
      }
    } else if (menu.textMenu === 'Delete') {
      setSelectedZone(id);

      setShowDeleteZoneConfirmation(true);
    }
  };

  const onConfirmDeleteZone = async (result: YesNoDialogResult) => {
    if (result === 'yes') {
      if (gameDocument) {
        //Delete zone from asset.zone

        let zone = getZoneByIndex(selectedZone);

        DeleteZoneAsync(state.gameDocument!, zone?.id!).then(
          (updatedGameDocument) => {
            //delete zone from worldmap.zone

            let zoneIndex =
              updatedGameDocument?.rules?.worldMap?.zones?.findIndex(
                (x) => x.mapAssId === zone?.id
              );

            updatedGameDocument?.rules?.worldMap?.zones?.splice(zoneIndex, 1);
            setState((prev) => UpdateGameDocState(prev, updatedGameDocument));
          }
        );
      }
    }

    setShowDeleteZoneConfirmation(false);
  };

  const showZone = (zone: ZoneEntity, isVisible: boolean) => {
    if (gameDocument) {
      let newGame = { ...gameDocument };

      let { zones } = gameDocument.assets;

      if (zones) {
        const zoneIndex = zones.findIndex((x) => x.id === zone.id);

        zones[zoneIndex].isVisible = isVisible;

        newGame.assets.zones = zones;

        setState((state) => UpdateGameDocState(state, newGame));
      }
    }
  };

  // #endregion "Zone"

  // #region "Area"

  const handleSearchAreaChange = (value: string) => {
    setSearchAreaText(value);
  };

  const showArea = async (area: AreaEntity, isVisible: boolean) => {
    if (gameDocument) {
      area.isVisible = isVisible;
      const result = await UpdateAreaAsync(gameDocument, area.id, area);
      setState((prev) => UpdateGameDocState(prev, result));
    }
  };
  const [areaMenu, setAreaMenu] = useState<MenuList[]>([]);

  const generateAreaMenu = () => {
    let menus: MenuList[] = [
      { classIcon: 'edit', textMenu: 'Edit', textClass: '' },
      { classIcon: 'delete', textMenu: 'Delete', textClass: 'text-danger' }
    ];
    setAreaMenu(menus);
  };

  const toggleActionsAreaDialog = () => {
    setActionsAreaDialogVisible(!actionsAreaDialogVisible);
  };

  const toggleActionsRouteDialog = () => {
    setActionsRoutesDialogVisible(!actionsRoutesDialogVisible);
  };
  const toggleActionsMapImageDialog = () => {
    setActionsMapImageDialogVisible(!actionsMapImageDialogVisible);
  };

  const getAreaByIndex = (index: number) => {
    return areas
      ?.filter((area) =>
        area?.name?.toLowerCase().includes(searchAreaText.toLowerCase())
      )
      .at(index);
  };

  const handleAreaMenuSelected = (id: number, menu: MenuList) => {
    if (menu.textMenu === 'Edit') {
      setSelectedAreaId(id);
      const areaData = getAreaByIndex(id);
      if (areaData) {
        setTitleDialog(areaData.name as any);
        toggleActionsAreaDialog();
      }
    } else if (menu.textMenu === 'Delete') {
      setSelectedAreaId(id);
      setShowDeleteAreaConfirmation(true);
    }
  };

  const onConfirmDeleteArea = async (result: YesNoDialogResult) => {
    if (result === 'yes') {
      if (gameDocument) {
        //Delete area from asset
        const resultArea = await DeleteAreaAsync(
          gameDocument,
          getAreaByIndex(selectedAreaId)?.id!
        );

        if (zoneId) {
          //delete area from asset.zones.area
          removeAreaFromZone(resultArea, selectedAreaId);
        } else {
          setState((prev) => UpdateGameDocState(prev, resultArea));
        }
      }
    }

    setShowDeleteAreaConfirmation(false);
  };

  const removeAreaFromZone = async (
    gameDocument: GameDocument,
    index: number
  ) => {
    if (gameDocument) {
      const areaId = getAreaByIndex(index)?.id;
      const zone = GetZoneById(gameDocument, zoneId);

      if (zone) {
        const areaIndex = zone?.areas?.findIndex((x) => x.areaAssId === areaId);
        zone.areas?.splice(areaIndex!, 1);

        const resultZone = await UpdateZoneAsync(gameDocument, zoneId, zone);
        setState((prev) => UpdateGameDocState(prev, resultZone));
      }
    }
  };

  const populateAreas = () => {
    if (gameDocument) {
      let areas = [];

      if (zoneId) {
        areas = GetAreasByZoneId(gameDocument, zoneId);
      } else {
        areas = GetAreasWorldMap(gameDocument);
      }

      setAreas(areas);
    }
  };

  // #endregion "Area"

  // #region "Task"
  const populateTask = () => {
    if (zoneId) {
      setTasks(GetTasksByZoneId(zoneId, gameDocument) ?? []);
    } else {
      const taskIds = gameDocument?.rules?.worldMap?.tasks?.map(
        (task) => task.taskAssId
      );

      if (taskIds) {
        setTasks(GetTasksByIds(gameDocument, taskIds) ?? []);
      }
    }
  };

  const showTask = async (task: TaskEntity, isVisible: boolean) => {
    if (gameDocument) {
      task.isVisible = isVisible;
      const result = await UpdateTaskAsync(gameDocument, task.id, task);
      setState((state) => UpdateGameDocState(state, result));
    }
  };

  const showMapImage = async (mapImage: MapImage, isVisible: boolean) => {
    if (gameDocument) {
      mapImage.isVisible = isVisible;
      const result = await UpdateMapImagesAsync(gameDocument, mapImage);
      setState((state) => UpdateGameDocState(state, result));
    }
  };

  const handleTaskEntity = (
    editorEntity: EntityEditor<TaskEntity>,
    resourceEntity: EntityEditor<ResourceEntity>[]
  ) => {
    SaveEntityEditorAsync(
      state?.gameDocument!,
      editorEntity,
      resourceEntity
    ).then((response) => {
      if (response) {
        setState((prev) => UpdateGameDocState(prev, response!));
      }

      toggleEditTaskDialog();
    });
  };

  const isAbleToUploadImage = async () => {
    let map = await getMapEntityAsync();

    if (map?.type !== 'illustration') {
      setAbleUploadImage(true);
    } else {
      if (!map?.imageResId) {
        setAbleUploadImage(false);
      } else {
        setAbleUploadImage(true);
      }
    }
  };

  const onConfirmDeleteTask = async (result: YesNoDialogResult) => {
    if (result === 'yes') {
      // delete overlay here
      if (gameDocument) {
        let selectedTask = getTaskByIndex(currentTaskIdForDelete);
        if (selectedTask) {
          const result = await RemoveTaskBoundaryAsync(
            gameDocument,
            selectedTask.id
          );
          if (!zoneId) {
            //delete task from worldmap.tasks
            let selectedTaskIndex = result?.rules?.worldMap?.tasks?.findIndex(
              (x) => x.taskAssId === selectedTask.id
            );

            result?.rules?.worldMap?.tasks?.splice(selectedTaskIndex!, 1);
            setState((prev) => UpdateGameDocState(prev, result!));
          } else {
            //delete task from asset.zones.tasks
            await removeTaskFromZone(result, currentTaskIdForDelete);
          }

          removeTaskOverlay(selectedTask.id);
        }
      }
    }

    setShowDeleteTaskConfirm(false);
  };

  const removeTaskFromZone = async (
    gameDocument: GameDocument,
    index: number
  ) => {
    if (zoneId && gameDocument) {
      let zone = GetZoneById(gameDocument, zoneId);

      if (zone) {
        let selectedTask = getTaskByIndex(currentTaskIdForDelete!);
        let selectedTaskIndex = zone?.tasks?.findIndex(
          (x) => x.taskAssId === selectedTask?.id
        );

        zone.tasks?.splice(selectedTaskIndex!, 1);
        const resultZone = await UpdateZoneAsync(gameDocument, zoneId, zone);
        setState((prev) => UpdateGameDocState(prev, resultZone));
      }
    }
  };

  const [selectedTask, setSelectedTask] = useState<TaskEntity>();

  const getTaskByIndex = (index: number) => {
    if (gameDocument) {
      let { tasks } = gameDocument?.assets;

      let taskassetIds: string[] = [];

      if (zoneId) {
        taskassetIds = GetTasksByZoneId(zoneId, gameDocument)?.map(
          (item) => item.id
        );
      } else {
        taskassetIds = gameDocument?.rules?.worldMap?.tasks?.map(
          (item) => item.taskAssId
        );
      }

      return tasks
        ?.filter((task) =>
          task?.name?.toLowerCase().includes(searchTaskText.toLowerCase())
        )
        ?.filter((x) => taskassetIds?.includes(x.id))
        .at(index);
    }
  };

  const handleTaskMenuSelected = (id: number, menu: MenuList) => {
    if (menu.textMenu === 'Edit') {
      setActionsEditTaskDialogVisible(true);

      if (!zoneId) {
        // find task by id coordinate
        const taskData = getTaskByIndex(id);

        setSelectedTask(taskData);
        onEditTaskEntity(taskData!);
      } else {
        setSelectedTask(getTaskByIndex(id));
        onEditTaskEntity(getTaskByIndex(id)!);
      }

      if (selectedTask) {
        setTitleDialog(selectedTask.name);
      }
    } else if (menu.textMenu === 'Copy') {
      setSelectedPanel('Task');
      onSelectedPanel && onSelectedPanel('Task');
      copyTask(id);
    } else if (menu.textMenu === 'Delete') {
      setCurrentTaskIdForDelete(id);
      setShowDeleteTaskConfirm(true);
    }
  };

  const copyTask = async (taskIndex: number) => {
    if (taskIndex < 0) return;

    let newTaskId = uuid();

    if (zoneId !== '') {
      let zone = await GetZoneById(state?.gameDocument, zoneId);

      if (!zone) return;

      let taskId = getTaskByIndex(taskIndex)?.id;

      if (taskId) {
        const newGameDocument = await CopyTaskAsync(
          gameDocument!,
          taskId,
          newTaskId
        );

        zone?.tasks?.push({ taskAssId: newTaskId });

        const resultZone = await UpdateZoneAsync(
          newGameDocument!,
          zoneId,
          zone!
        );
        setState((prevState) => UpdateGameDocState(prevState, resultZone));
      }
    } else {
      let taskId = getTaskByIndex(taskIndex)?.id;

      if (taskId) {
        const newGameDocument = await CopyTaskAsync(
          gameDocument!,
          taskId,
          newTaskId
        );
        newGameDocument?.rules?.worldMap?.tasks.push({ taskAssId: newTaskId });
        setState((prev) => UpdateGameDocState(prev, newGameDocument!));
      }
    }
  };

  const handleRouteMenuSelected = (id: string, menu: MenuList) => {
    setSelectedRouteId(id);
    if (menu.textMenu === 'Edit') {
      toggleActionsRouteDialog();
    } else if (menu.textMenu === 'Delete') {
      toggleRouteDeleteDialog();
    }
  };
  const handleMapImagesMenuSelected = (id: string, menu: MenuList) => {
    setSelectedMapImageId(id);
    if (menu.textMenu === 'Edit') {
      toggleActionsMapImageDialog();
    } else if (menu.textMenu === 'Delete') {
      toggleMapImageDeleteDialog();
    }
  };

  const handleShowTaskTitle = () => {
    setIsShowTasknameVisibility(!isShowTasknameVisibility);
    let updatedGameDocument = state.gameDocument;
    if (updatedGameDocument?.overview) {
      updatedGameDocument.overview.showTasknameVisibility =
        !isShowTasknameVisibility;
      setState((state) => ({
        ...state,
        isDirty: true,
        gameDocument: updatedGameDocument
      }));
    }

    const taskContainer = document.querySelectorAll('[id^="taskContainer-"]');
    taskContainer.forEach((task) => {
      const detailTaskContainer = task.querySelector(
        '[id^="detailTaskContainer-"]'
      );
      const taskName = task.querySelector('[id^="taskName-"]');
      if (isShowTasknameVisibility) {
        task.classList.remove('expanded');
        taskName?.classList.add('d-none');
        detailTaskContainer?.classList.remove('visible');
        detailTaskContainer?.classList.add('hidden');
      } else {
        task.classList.add('expanded');
        taskName?.classList.remove('d-none');
        detailTaskContainer?.classList.remove('hidden');
        detailTaskContainer?.classList.add('visible');
      }
    });
  };

  const onEditTaskEntity = (entity: TaskEntity) => {
    if (entity) {
      setEntityEditorValue({
        isNew: false,
        entity: entity
      });

      let resources: EntityEditor<ResourceEntity>[] = [];

      for (const res of GetResourceKeys(entity)) {
        resources.push({
          isNew: false,
          entity: GetResourceEntity(state.gameDocument!, entity[res])
        });
      }

      setResourceEditorValue(resources);
    }
  };

  const handleSearchTaskChange = (value: string) => {
    setSearchTaskText(value);
  };

  const toggleEditTaskDialog = () => {
    setActionsEditTaskDialogVisible(!actionsEditTaskDialogVisible);
  };

  //#endregion "Task"

  const handleCancelInteractions = () => {
    setSelectedPanel('');
    onSelectedPanel && onSelectedPanel('');
  };

  const getWorldMapAsset = async () => {
    return await GetMapByIdAsync(
      state?.gameDocument!,
      state?.gameDocument?.rules?.worldMap?.mapAssId!
    );
  };

  const updateMapAsset = (map: MapEntity) => {
    UpdateMapAsync(state?.gameDocument!, map.id, map).then((response) => {
      setState((prev) => UpdateGameDocState(prev, response));
    });
  };

  const getAssetRoutes = async () => {
    return await GetDetailRoutesByMapId(
      state?.gameDocument!,
      state?.gameDocument?.rules?.worldMap?.mapAssId!
    );
  };

  const updateRoutesAsset = (route: TaskRoute) => {
    UpdateRoutesAsync(state?.gameDocument!, route).then((response) => {
      setState((prev) => UpdateGameDocState(prev, response));
    });
  };

  const RemoveRoutes = (route: TaskRoute) => {
    RemoveRoutesAsync(state?.gameDocument!, route).then((response) => {
      setState((prev) => UpdateGameDocState(prev, response));
    });
  };
  const RemoveMapImage = async (mapImage: MapImage) => {
    const response = await DeleteResourceAsync(
      state.gameDocument!,
      mapImage.titleResId!
    );
    await RemoveMapImageAsync(response!, mapImage).then((response) => {
      setState((prev) => UpdateGameDocState(prev, response));
    });
  };

  const updateMapImageAsset = (mapImage: MapImage) => {
    UpdateMapImagesAsync(state.gameDocument!, mapImage).then((response) => {
      setState((prev) => UpdateGameDocState(prev, response));
    });
  };

  const updateMapResource = async (
    mapEntity: MapEntity,
    uploadedImage: UploadedImage
  ) => {
    if (mapEntity.imageResId) {
      gameDocumentFiles.updateResourceFile(mapEntity.imageResId, uploadedImage);
    } else {
      const resourceId = uuid();
      mapEntity.imageResId = resourceId;
      const response = await AddResourceAsync(
        state.gameDocument!,
        resourceId,
        uploadedImage.fileName ?? '',
        'image',
        uploadedImage.blobUrl,
        resourceId,
        uploadedImage.size
      );
      setState((prev) => UpdateGameDocState(prev, response));
    }
  };

  const handleFilesSelected = async (uploadedImage: UploadedImage) => {
    setDialogVisible(false);
    if (currentImageResource === uploadedImage.blobUrl) return;

    if (state.gameDocument) {
      if (zoneId !== '') {
        // Zone map upload
        const zoneMapAssetId: string =
          state.gameDocument?.rules.worldMap.zones.find(
            (zone: ZoneAssetId) => zone.zoneAssId === zoneId
          )?.mapAssId ?? '';
        const mapEntity: MapEntity =
          (await GetMapByIdAsync(state.gameDocument, zoneMapAssetId)) ??
          ({} as MapEntity);
        await updateMapResource(mapEntity, uploadedImage);
        updateMapAsset(mapEntity);
      } else {
        // World map upload
        const mapEntity: MapEntity =
          (await getWorldMapAsset()) ?? ({} as MapEntity);
        await updateMapResource(mapEntity, uploadedImage);
        updateMapAsset(mapEntity);
      }
      populateImageResource();
    }
  };

  const getMapEntityAsync = async () => {
    if (zoneId) {
      const mapAssId = gameDocument?.rules.worldMap.zones.find(
        (z) => z.zoneAssId === zoneId
      )?.mapAssId;
      return await GetMapByIdAsync(state?.gameDocument!, mapAssId!);
    } else {
      return await getWorldMapAsset();
    }
  };

  const handleMapImageFilesSelected = async (uploadedImage: UploadedImage) => {
    setDialogVisible(false);
    if (currentImageResource === uploadedImage.blobUrl) return;

    // if (state.gameDocument) {
    //   if (zoneId !== '') {
    //     // Zone map upload
    //     const zoneMapAssetId: string =
    //       state.gameDocument?.rules.worldMap.zones.find(
    //         (zone: ZoneAssetId) => zone.zoneAssId === zoneId
    //       )?.mapAssId ?? '';
    //     const mapEntity: MapEntity =
    //       (await GetMapByIdAsync(state.gameDocument, zoneMapAssetId)) ??
    //       ({} as MapEntity);
    //     await updateMapResource(mapEntity, uploadedImage);
    //     updateMapAsset(mapEntity);
    //   } else {
    //     // World map upload
    //     const mapEntity: MapEntity =
    //       (await getWorldMapAsset()) ?? ({} as MapEntity);
    //     await updateMapResource(mapEntity, uploadedImage);
    //     updateMapAsset(mapEntity);
    //   }
    //   populateImageResource();
    // }
  };

  const onHandleMapOptionChange = (e: RadioButtonChangeEvent) => {
    onChangeMapOptions && onChangeMapOptions(e.value);
  };

  const toggleDialog = () => {
    setDialogVisible(!dialogVisible);
  };

  const toggleActionsEventDialogVisible = useCallback(() => {
    setActionsEventDialogVisible(!actionsEventDialogVisible);
  }, [actionsEventDialogVisible]);

  const onHandleOpenMapIllustration = () => {
    setShowDeleteIllustrationConfirm(true);
  };

  const onConfirmDeleteIllustration = async (result: YesNoDialogResult) => {
    if (result === 'yes') {
      if (zoneId !== '') {
        const mapAssId = gameDocument?.rules.worldMap.zones.find(
          (z) => z.zoneAssId === zoneId
        )?.mapAssId;
        const updatedMap = await GetMapByIdAsync(
          state?.gameDocument!,
          mapAssId!
        );
        if (updatedMap) {
          const response = await DeleteResourceAsync(
            state.gameDocument!,
            updatedMap.imageResId!
          );
          setState((prev) => UpdateGameDocState(prev, response));
          updatedMap.imageResId = '';
          updateMapAsset(updatedMap!);
        }
      } else {
        let mapEntity = await getWorldMapAsset();
        if (mapEntity) {
          const response = await DeleteResourceAsync(
            state.gameDocument!,
            mapEntity.imageResId!
          );
          setState((prev) => UpdateGameDocState(prev, response));
          mapEntity.imageResId = '';
          updateMapAsset(mapEntity);
        }
      }
    }

    setShowDeleteIllustrationConfirm(false);
  };

  const onSearchPlace = async (e: AutoCompleteChangeEvent) => {
    try {
      clearTimeout(filterTimeout);
      filterTimeout = setTimeout(async () => {
        let response = await SearchPlaceAsync(e.value);

        if (response) {
          setSearchResult(response.data as Place[]);
        }
      }, 350);
    } catch (error) {
      console.error(error);
    }
  };

  const getBoundsZoomLevel = (
    viewPort: { northEast: Coordinate; southWest: Coordinate },
    mapDim: { height: number; width: number }
  ) => {
    let WORLD_DIM = { height: 256, width: 256 };
    let ZOOM_MAX = 21;

    const latRad = (lat: number) => {
      let sin = Math.sin((lat * Math.PI) / 180);
      let radX2 = Math.log((1 + sin) / (1 - sin)) / 2;
      return Math.max(Math.min(radX2, Math.PI), -Math.PI) / 2;
    };

    const zoom = (mapPx: number, worldPx: number, fraction: number) => {
      return Math.floor(Math.log(mapPx / worldPx / fraction) / Math.LN2);
    };

    let ne = viewPort.northEast;
    let sw = viewPort.southWest;

    let latFraction = (latRad(ne.lat) - latRad(sw.lat)) / Math.PI;

    let lngDiff = ne.lng - sw.lng;
    let lngFraction = (lngDiff < 0 ? lngDiff + 360 : lngDiff) / 360;

    let latZoom = zoom(mapDim.height, WORLD_DIM.height, latFraction);
    let lngZoom = zoom(mapDim.width, WORLD_DIM.width, lngFraction);

    return Math.min(latZoom, lngZoom, ZOOM_MAX);
  };

  const updateBoundaryCoordinates = (
    coordinates: number[][],
    currentCenter: number[],
    selectedLon: number,
    selectedLat: number
  ) => {
    return coordinates.map((coordinate) => {
      const offsetX = coordinate[0] - currentCenter[0];
      const offsetY = coordinate[1] - currentCenter[1];
      return [selectedLon + offsetX, selectedLat + offsetY];
    });
  };

  const calculateNewCoordinates = (
    currentCenter: number[],
    prevCoordinates: [number, number],
    selectedLon: number,
    selectedLat: number
  ): [number, number] => {
    const offsetX = prevCoordinates[0] - currentCenter[0];
    const offsetY = prevCoordinates[1] - currentCenter[1];

    return [selectedLon + offsetX, selectedLat + offsetY];
  };

  const updateTask = async (
    task: TaskEntity,
    currentCenter: number[],
    selectedLon: number,
    selectedLat: number,
    gameDocument: any
  ) => {
    if (task.boundary) {
      let boundary = task.boundary;

      boundary.geometry.coordinates = calculateNewCoordinates(
        currentCenter,
        boundary.geometry.coordinates as [number, number],
        selectedLon,
        selectedLat
      );

      task.boundary = boundary;
      if (gameDocument) {
        const result = await UpdateTaskAsync(gameDocument, task.id, task);
        setState((state) => UpdateGameDocState(state, result));
        updateTaskOverlayPosition(
          task.id,
          toLonLat(boundary.geometry.coordinates)
        );
      }
    }
  };

  const updateZone = async (
    zone: ZoneEntity,
    currentCenter: number[],
    selectedLon: number,
    selectedLat: number,
    gameDocument: any
  ) => {
    if (zone.boundary) {
      zone.boundary.geometry.coordinates[0] = updateBoundaryCoordinates(
        zone.boundary.geometry.coordinates[0],
        currentCenter,
        selectedLon,
        selectedLat
      );
      if (gameDocument) {
        const resultZone = await UpdateZoneAsync(gameDocument, zone.id, zone);
        setState((prev) => UpdateGameDocState(prev, resultZone));
      }
    }
  };

  const updateArea = async (
    area: AreaEntity,
    currentCenter: number[],
    selectedLon: number,
    selectedLat: number,
    gameDocument: any
  ) => {
    if (area.boundary) {
      area.boundary.geometry.coordinates[0] = updateBoundaryCoordinates(
        area.boundary.geometry.coordinates[0],
        currentCenter,
        selectedLon,
        selectedLat
      );
      if (gameDocument) {
        const resultArea = await UpdateAreaAsync(gameDocument, area.id, area);
        setState((prev) => UpdateGameDocState(prev, resultArea));
      }
    }
  };

  const updateImage = async (
    image: MapImage,
    currentCenter: number[],
    selectedLon: number,
    selectedLat: number,
    gameDocument: any
  ) => {
    if (image.boundary) {
      let boundary = image.boundary;
      boundary.geometry.coordinates = calculateNewCoordinates(
        currentCenter,
        boundary.geometry.coordinates as [number, number],
        selectedLon,
        selectedLat
      );
      image.boundary = boundary;
      if (gameDocument) {
        const resultImage = await UpdateMapImagesAsync(gameDocument, image);
        setState((prev) => UpdateGameDocState(prev, resultImage));
      }
    }
  };

  const onPlaceSelected = async (e: AutoCompleteCloseEvent) => {
    const placeName = e.target.value;
    const place = searchResult.find((x) => x.display_name === placeName);
    setSelectedPlace(place);
  };

  const onRecentreLocation = async (place: Place) => {
    if (place) {
      try {
        const currentCenter = map.getView().getCenter();
        if (!currentCenter) return;

        const [selectedLon, selectedLat] = fromLonLat([place.lon, place.lat]);
        map.getView().setCenter([selectedLon, selectedLat]);
        await Promise.all([
          ...tasks.map((task) =>
            updateTask(
              task,
              currentCenter,
              selectedLon,
              selectedLat,
              gameDocument
            )
          ),
          ...(zones
            ? zones.map((zone) =>
                updateZone(
                  zone,
                  currentCenter,
                  selectedLon,
                  selectedLat,
                  gameDocument
                )
              )
            : []),
          ...areas.map((area) =>
            updateArea(
              area,
              currentCenter,
              selectedLon,
              selectedLat,
              gameDocument
            )
          ),
          ...getMapImages().map((image) =>
            updateImage(
              image,
              currentCenter,
              selectedLon,
              selectedLat,
              gameDocument
            )
          )
        ]);
      } catch (error) {
        console.error(error);
      }
    }
  };

  const onSearchCoordinates = (lat: string, lon: string) => {
    map.getView().setCenter(fromLonLat([parseFloat(lon), parseFloat(lat)]));
  };

  const handleEventChange = async (
    algorithmOnEnter: Algorithm,
    algorithmOnExit: Algorithm,
    algorithmOnTaskCompleted: Algorithm
  ) => {
    if (gameDocument === undefined) {
      return;
    }
    const newAlgorithmOnEnter: Algorithm = algorithmOnEnter as Algorithm;
    const newAlgorithmOnExit: Algorithm = algorithmOnExit as Algorithm;
    const newAlgorithmOnTaskCompleted: Algorithm =
      algorithmOnTaskCompleted as Algorithm;
    if (zoneId === '') {
      gameDocument.rules.worldMap.events = {
        onEnterMap: newAlgorithmOnEnter,
        onExitMap: newAlgorithmOnExit,
        onTaskCompletedMap: newAlgorithmOnTaskCompleted
      };
      setState((prev) => UpdateGameDocState(prev, gameDocument));
    } else {
      let zone = await GetZoneById(state?.gameDocument, zoneId);
      if (zone) {
        zone.events = {
          onEnterMap: newAlgorithmOnEnter,
          onExitMap: newAlgorithmOnExit,
          onTaskCompletedMap: newAlgorithmOnTaskCompleted
        };
        const resultZone = await UpdateZoneAsync(gameDocument, zoneId, zone!);
        setState((prevState) => UpdateGameDocState(prevState, resultZone));
      }
    }

    toggleActionsEventDialogVisible();
  };

  const onSetPopupTitle = (title: string) => {
    setTitleDialog(title);
  };

  const EventButtonHandler = useCallback(() => {
    toggleActionsEventDialogVisible();
    onSetPopupTitle('Event configurations');
  }, [toggleActionsEventDialogVisible]);

  const handleSearchZoneChange = (value: string) => {
    setSearchZoneText(value);
  };

  const handleInputChange = async (event: InputChangeEvent) => {
    if (event.target.name === 'titleResId') {
      const { name, value } = event.target;
      setResources(
        resources.map((item) =>
          item.id === entity![name]
            ? { ...item, value: value! as string }
            : { ...item }
        )
      );
      const resource = resources.find(
        (item) => item.id === entity?.titleResId!
      )!;
      resource.value = value as string;

      UpdateResourceAsync(state.gameDocument!, resource?.id, resource).then(
        (updatedGameDocument) => {
          setState((prev) => UpdateGameDocState(prev, updatedGameDocument));
        }
      );
    } else {
      event.target.name === 'name'
        ? setZoneName(event.value)
        : setZoneDescription(event.value);
      await UpdateZoneAsync(state.gameDocument!, zoneId!, {
        ...entity!,
        [event.target.name!]: event.target.value
      }).then((updatedGameDocument) => {
        setState((state) => UpdateGameDocState(state, updatedGameDocument));
      });
    }
  };

  const onChangeEditTimerHandler = (value: string) => {
    if (gameDocument) {
      gameDocument.rules.worldMap.timerAssId = value;
      setState((state) => UpdateGameDocState(state, gameDocument));
    }
  };

  const onChangeEditZoneTimerHandler = async (value: string) => {
    if (gameDocument) {
      // find zones by zoneId

      if (zoneId !== '' || zoneId !== undefined) {
        const zoneIndex = gameDocument?.rules?.worldMap?.zones?.findIndex(
          (x) => x?.zoneAssId === zoneId
        );
        if (zoneIndex !== -1) {
          gameDocument.rules.worldMap.zones[zoneIndex].timerAssId = value;
        }
        setState((state) => UpdateGameDocState(state, gameDocument));
      }
    }
  };

  const populateImageResource = async () => {
    //Edit zone
    if (zoneId !== '') {
      const mapAssId = gameDocument?.rules.worldMap.zones.find(
        (z) => z.zoneAssId === zoneId
      )?.mapAssId;
      if (mapAssId !== undefined || mapAssId !== '') {
        let mapEntity = await GetMapByIdAsync(state?.gameDocument!, mapAssId!);
        if (mapEntity) {
          if (
            mapEntity.imageResId !== undefined ||
            mapEntity.imageResId !== ''
          ) {
            let resource = GetResourceEntity(
              state?.gameDocument!,
              mapEntity.imageResId!
            );
            setImageResource(resource);
          }
        }
      }
    } else {
      let mapEntity = await getWorldMapAsset();
      if (mapEntity) {
        if (mapEntity.imageResId !== undefined || mapEntity.imageResId !== '') {
          let resource = GetResourceEntity(
            state?.gameDocument!,
            mapEntity.imageResId!
          );
          setImageResource(resource);
        }
      }
    }
  };

  // #region "useEffect"

  useEffect(() => {
    generateTaskMenu();
    generateZoneMenu();
    generateAreaMenu();

    if (zoneId) {
      let zone = gameDocument?.assets?.zones?.find((z) => z.id === zoneId);
      if (zone) {
        setZoneName(zone?.name ?? '');
        setZoneDescription(zone?.description ?? '');
      }
    }
    populateTask();
    populateAreas();

    let zones: ZoneEntity[] = [];
    zones.push(
      gameDocument?.assets?.zones?.find((z) => z.id === zoneId) as ZoneEntity
    );

    const zone = getEntityById(zoneId);
    if (zone) {
      const zoneRes = state.gameDocument?.resources.find(
        (item) => item.id === zone.titleResId!
      )! as ResourceEntity;
      if (zoneRes !== undefined) {
        setEntity(zone);
        setResources([zoneRes]);
      }
    }
  }, [state]);

  useEffect(() => {
    populateTask();
    isAbleToUploadImage();
  }, [gameDocument]);

  useEffect(() => {
    populateImageResource();
  }, [zoneId, state]);

  useEffect(() => {
    if (taskId) {
      toggleEditTaskDialog();
      const taskData = gameDocument?.assets?.tasks?.find(
        (x) => x.id === taskId
      );

      setSelectedTask(taskData);
      onEditTaskEntity(taskData!);
    }
    return;
  }, [taskId]);

  // #endregion "useEffect"

  return (
    <>
      <div className={'p-3 d-flex w-full text-primary'}>
        <Checkbox
          size={'large'}
          value={selectedAllObjectType === 'ALL'}
          onChange={(e) => onSelectAllAssets(e.target.value ? 'ALL' : '')}
          label={'Select all assets'}
        />
      </div>

      <PanelBar expandMode={'single'}>
        {showMapAccordion && (
          <PanelBarItem
            title={'Map'}
            expanded={true}
            aria-hidden={true}
            selected={true}>
            <MapSettings
              selectedMapOption={mapOptions!}
              onSelectedMapChange={onHandleMapOptionChange}
              mapInfo={mapInfo}
              onCenterMap={onCenterMap}
              cities={searchResult.map((x) => x.display_name)}
              onPlaceSelected={onPlaceSelected}
              onSearchPlace={onSearchPlace}
              onSearchCoordinates={onSearchCoordinates}
              onSelectIllustrationMapClick={toggleDialog}
              illustrationMapMedia={imageResource}
              onDeleteIlustrationMap={onHandleOpenMapIllustration}
              onEventButtonClick={EventButtonHandler}
              onRecentreLocation={onRecentreLocation}
              selectedPlace={selectedPlace}>
              <DropDownList
                data={state.gameDocument?.assets.timers ?? []}
                value={
                  state.gameDocument?.assets.timers?.find(
                    (x) =>
                      x.id === state.gameDocument?.rules.worldMap?.timerAssId
                  ) ?? { name: 'Add timer to map' }
                }
                textField={'name'}
                defaultItem={{ name: '(No timer)', id: '' }}
                className={'mt-3'}
                onChange={(e: DropDownListChangeEvent) => {
                  onChangeEditTimerHandler((e.value as TimerEntity).id ?? '');
                }}
              />
            </MapSettings>
          </PanelBarItem>
        )}

        {showZonesAccordion && (
          <PanelBarItem
            title={'Zones'}
            expanded={zoneId ? true : false}
            selected={zoneId ? true : false}>
            {zoneId && (
              <>
                <StandardInput
                  name={'name'}
                  label={'Name'}
                  value={zoneName}
                  required={true}
                  autoFocus={true}
                  onChange={(e) => handleInputChange(e)}
                />
                <StandardInput
                  name={'description'}
                  label={'Description'}
                  value={zoneDescription}
                  onChange={(e) => handleInputChange(e)}
                />
                <StandardInput
                  name={'titleResId'}
                  label={'Title'}
                  value={
                    resources.find(
                      (resource) => resource.id === entity?.titleResId
                    )?.value ?? ''
                  }
                  onChange={(e) => handleInputChange(e)}
                />
                <MapSettings
                  selectedMapOption={mapOptions!}
                  onSelectedMapChange={onHandleMapOptionChange}
                  mapInfo={mapInfo}
                  onCenterMap={onCenterMap}
                  cities={searchResult.map((x) => x.display_name!)}
                  onPlaceSelected={onPlaceSelected}
                  onSearchPlace={onSearchPlace}
                  onSearchCoordinates={onSearchCoordinates}
                  onSelectIllustrationMapClick={toggleDialog}
                  illustrationMapMedia={imageResource}
                  onDeleteIlustrationMap={onHandleOpenMapIllustration}
                  onEventButtonClick={EventButtonHandler}
                  onRecentreLocation={onRecentreLocation}
                  selectedPlace={selectedPlace}>
                  <DropDownList
                    data={state.gameDocument?.assets.timers ?? []}
                    value={
                      state.gameDocument?.assets?.timers?.find(
                        (x) =>
                          state.gameDocument?.rules?.worldMap?.zones?.find(
                            (item) => item?.zoneAssId === zoneId
                          )?.timerAssId === x.id
                      ) ?? { name: 'Add timer to zone' }
                    }
                    textField={'name'}
                    defaultItem={{ name: '(No timer)', id: '' }}
                    className={'mt-3'}
                    onChange={(event: DropDownListChangeEvent) => {
                      onChangeEditZoneTimerHandler(
                        (event.value as TimerEntity).id ?? ''
                      );
                    }}
                  />
                </MapSettings>
              </>
            )}

            {!zoneId && (
              <div className={'position-relative'}>
                <>
                  <div className={'d-flex my-3 w-full text-primary'}>
                    <Checkbox
                      value={
                        selectedAllObjectType === 'ZONE' ||
                        selectedAllObjectType === 'ALL'
                      }
                      size={'large'}
                      onChange={(e) =>
                        onSelectAllAssets(e.target.value ? 'ZONE' : '')
                      }
                      label={'Select all zones'}
                    />
                  </div>
                  <Input
                    type={'text'}
                    className={'form-control mb-1'}
                    value={searchZoneText}
                    placeholder={'Search'}
                    onChange={(e) => handleSearchZoneChange(e.value)}></Input>
                </>
              </div>
            )}

            {!zoneId && (
              <Button
                title={selectedPanel !== 'Zone' ? 'zone' : 'zone cancel'}
                themeColor={selectedPanel !== 'Zone' ? 'success' : 'warning'}
                className={'flex-fill w-full mt-2'}
                onClick={() => {
                  if (selectedPanel !== 'Zone') {
                    setSelectedPanel('Zone');
                    onSelectedPanel && onSelectedPanel('Zone');
                  } else {
                    handleCancelInteractions();
                  }
                }}>
                <span className={'material-symbols-outlined mx-2'}>
                  activity_zone
                </span>
                {selectedPanel !== 'Zone' ? 'Add zone' : 'Cancel add zone'}
              </Button>
            )}

            {!zoneId && (
              <div
                className={'my-3 d-flex align-items-center flex-column gap-2'}>
                {zones &&
                  zones
                    .filter((zone) =>
                      zone.name
                        ?.toLowerCase()
                        .includes(searchZoneText.toLowerCase())
                    )
                    .map((zone, index) => (
                      <div
                        key={zone.id}
                        className={
                          'd-flex w-100 justify-content-between align-items-center'
                        }>
                        {zone.isVisible && (
                          <span
                            onClick={() => showZone(zone, false)}
                            className={
                              'material-symbols-outlined cursor-pointer'
                            }>
                            activity_zone
                          </span>
                        )}
                        {!zone.isVisible && (
                          <span
                            onClick={() => showZone(zone, true)}
                            className={
                              'text-danger material-symbols-outlined cursor-pointer'
                            }>
                            visibility_off
                          </span>
                        )}
                        <div className={'card w-100 bg-secondary '}>
                          <div
                            className={
                              'card-body p-2 d-flex w-100 justify-content-between align-items-center'
                            }>
                            <span className={'text-light'}>{zone.name}</span>
                            <div>
                              <PopupMenu
                                id={index}
                                menus={zoneMenu}
                                onMenuSelected={handleZoneMenuSelected}
                                iconClass={
                                  'k-icon k-i-more-vertical h5 mb-0 text-light'
                                }></PopupMenu>
                            </div>
                          </div>
                        </div>
                        <div></div>
                      </div>
                    ))}
              </div>
            )}
          </PanelBarItem>
        )}

        {mapOptions !== 'Illustration Map' && showAreasAccordion && (
          <PanelBarItem title={'Areas'} expanded={false}>
            <div className={'position-relative'}>
              <>
                <div className={'d-flex my-3 w-full text-primary'}>
                  <Checkbox
                    value={
                      selectedAllObjectType === 'AREA' ||
                      selectedAllObjectType === 'ALL'
                    }
                    size={'large'}
                    onChange={(e) =>
                      onSelectAllAssets(e.target.value ? 'AREA' : '')
                    }
                    label={'Select all areas'}></Checkbox>
                </div>
                <Input
                  type={'text'}
                  className={'form-control mb-1'}
                  value={searchAreaText}
                  placeholder={'Search'}
                  onChange={(e) => handleSearchAreaChange(e.value)}></Input>
                <span
                  className={
                    'material-symbols-outlined position-absolute top-50 end-0 translate-middle-y mx-2 cursor-pointer z-index-2'
                  }>
                  search
                </span>
              </>
            </div>

            <Button
              title={selectedPanel !== 'Area' ? 'area' : 'area cancel'}
              themeColor={selectedPanel !== 'Area' ? 'success' : 'warning'}
              className={'flex-fill w-full mt-2'}
              onClick={() => {
                if (selectedPanel !== 'Area') {
                  setSelectedPanel('Area');
                  onSelectedPanel && onSelectedPanel('Area');
                } else {
                  handleCancelInteractions();
                }
              }}>
              <span className={'material-symbols-outlined mx-2'}>
                activity_zone
              </span>
              {selectedPanel !== 'Area' ? 'Add area' : 'Cancel add area'}
            </Button>

            <div className={'my-3 d-flex align-items-center flex-column gap-2'}>
              {areas
                .filter((area) =>
                  area.name
                    ?.toLowerCase()
                    .includes(searchAreaText.toLowerCase())
                )
                .map((area, index) => (
                  <div
                    className={
                      'd-flex w-100 justify-content-between align-items-center'
                    }
                    key={area.id}>
                    {area.isVisible && (
                      <span
                        onClick={() => showArea(area, false)}
                        className={'material-symbols-outlined cursor-pointer'}>
                        activity_zone
                      </span>
                    )}
                    {!area.isVisible && (
                      <span
                        onClick={() => showArea(area, true)}
                        className={
                          'text-danger material-symbols-outlined cursor-pointer'
                        }>
                        visibility_off
                      </span>
                    )}
                    <div className={'card w-100 bg-secondary '}>
                      <div
                        className={
                          'card-body p-2 d-flex w-100 justify-content-between align-items-center'
                        }>
                        <span className={'text-light'}>{area.name}</span>
                        <div>
                          <PopupMenu
                            id={index as number}
                            menus={areaMenu}
                            onMenuSelected={handleAreaMenuSelected}
                            iconClass={
                              'k-icon k-i-more-vertical h5 mb-0 text-light'
                            }
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
            </div>
          </PanelBarItem>
        )}

        {showTasksAccordion && (
          <PanelBarItem title={'Tasks'} expanded={false}>
            {tasks && tasks.length > 0 && (
              <>
                <div className={'d-flex my-3 w-full text-primary'}>
                  <Checkbox
                    value={
                      selectedAllObjectType === 'TASK' ||
                      selectedAllObjectType === 'ALL'
                    }
                    size={'large'}
                    onChange={(e) =>
                      onSelectAllAssets(e.target.value ? 'TASK' : '')
                    }
                    label={'Select all tasks'}></Checkbox>
                </div>
                <div className={'position-relative'}>
                  <Input
                    type={'text'}
                    className={'form-control mb-1'}
                    value={searchTaskText}
                    placeholder={'Search'}
                    onChange={(e) => handleSearchTaskChange(e.value)}></Input>
                </div>
              </>
            )}

            <Button
              title={selectedPanel !== 'Task' ? 'task' : 'task cancel'}
              themeColor={selectedPanel !== 'Task' ? 'success' : 'warning'}
              className={
                selectedPanel !== 'Task'
                  ? 'w-full mt-2 flex-fill'
                  : 'flex-fill bg-warning text-dark mt-2 w-full'
              }
              onClick={() => {
                if (selectedPanel !== 'Task') {
                  setSelectedPanel('Task');
                  onSelectedPanel && onSelectedPanel('Task');
                } else {
                  handleCancelInteractions();
                }
              }}>
              <span className={'material-symbols-outlined mx-2'}>flag</span>
              {selectedPanel !== 'Task' ? 'Add task' : 'Cancel add task'}
            </Button>

            <div className={'d-flex my-3 w-full text-primary'}>
              <Checkbox
                name={'show-task-title'}
                label={'Show task title'}
                size={'large'}
                value={isShowTasknameVisibility}
                onChange={handleShowTaskTitle}
              />
            </div>

            {tasks
              ?.filter((task) =>
                task.name?.toLowerCase().includes(searchTaskText.toLowerCase())
              )
              .map((task, index) => (
                <div
                  key={task.id}
                  className={
                    'd-flex w-100 justify-content-between align-items-center mt-1'
                  }>
                  <span
                    onClick={() => {
                      showTask(task, !task.isVisible);

                      const taskContainer = document.getElementById(
                        `taskContainer-${task.id}`
                      );
                      if (taskContainer) {
                        if (task.isVisible) {
                          taskContainer.classList.remove('d-none');
                        } else {
                          taskContainer.classList.add('d-none');
                        }
                      }
                    }}
                    className={'material-symbols-outlined'}>
                    <span
                      className={`${task.isVisible ? '' : 'text-danger'} material-symbols-outlined`}>
                      {task.isVisible ? 'flag' : 'visibility_off'}
                    </span>
                  </span>
                  <div className={'card w-100 bg-secondary '}>
                    <div
                      className={
                        'card-body p-2 d-flex w-100 justify-content-between align-items-center'
                      }>
                      <span className={'text-light'}>{task.name}</span>
                      <div>
                        <PopupMenu
                          id={index}
                          menus={taskMenu}
                          onMenuSelected={handleTaskMenuSelected}
                          iconClass={
                            'k-icon k-i-more-vertical h5 mb-0 text-light'
                          }
                        />
                      </div>
                    </div>
                  </div>
                </div>
              ))}
          </PanelBarItem>
        )}

        <PanelBarItem title={'Annotations'} expanded={false}>
          {getRoutes().length > 0 && (
            <div className={'position-relative'}>
              <Input
                type={'text'}
                className={'form-control mb-1'}
                value={searchRouteText}
                placeholder={'Search'}
                onChange={(e) => setSearchRouteText(e.value)}></Input>
              <span
                className={
                  'material-symbols-outlined fs-5 position-absolute top-50 end-0 translate-middle-y mx-2 cursor-pointer z-index-2'
                }>
                search
              </span>
            </div>
          )}

          <Button
            title={'task'}
            themeColor={'success'}
            className={'w-full mt-2 flex-fill'}
            onClick={() => {
              setSelectedPanel('Annotation');
              onSelectedPanel && onSelectedPanel('Annotation');
              setSelectedRouteId('');
              toggleActionsRouteDialog();
            }}>
            <span className={'material-symbols-outlined mx-2'}>route</span>
            Add annotation
          </Button>

          {getRoutes()
            .filter((annotation: TaskRoute) =>
              annotation.name
                ?.toLowerCase()
                .includes(searchRouteText.toLowerCase())
            )
            .map((annotation, index) => (
              <div
                key={annotation.id}
                className={
                  'd-flex w-100 justify-content-between align-items-center mt-1'
                }>
                <div className={'card w-100 bg-secondary '}>
                  <div
                    className={
                      'card-body p-2 d-flex w-100 justify-content-between align-items-center'
                    }>
                    <span className={'text-light'}>{annotation.name}</span>
                    <div>
                      <PopupMenu
                        id={index}
                        menus={taskMenu.filter((x) => x.textMenu !== 'Copy')}
                        onMenuSelected={(id, menu) => {
                          handleRouteMenuSelected(getRoutes()[id].id, menu);
                        }}
                        iconClass={
                          'k-icon k-i-more-vertical h5 mb-0 text-light'
                        }
                      />
                    </div>
                  </div>
                </div>
              </div>
            ))}
        </PanelBarItem>

        <PanelBarItem title={'Images'} expanded={false}>
          {getMapImages().length > 0 && (
            <>
              <div className={'d-flex my-3 w-full text-primary'}>
                <Checkbox
                  value={
                    selectedAllObjectType === 'IMAGE' ||
                    selectedAllObjectType === 'ALL'
                  }
                  size={'large'}
                  label={'Select all images'}
                  onChange={(e) =>
                    onSelectAllAssets(e.target.value ? 'IMAGE' : '')
                  }
                />
              </div>
              <div className={'position-relative'}>
                <Input
                  type={'text'}
                  className={'form-control mb-1'}
                  value={searchImageText}
                  placeholder={'Search'}
                  onChange={(e) => setSearchImageText(e.value)}></Input>
                <span
                  className={
                    'material-symbols-outlined fs-5 position-absolute top-50 end-0 translate-middle-y mx-2 cursor-pointer z-index-2'
                  }>
                  search
                </span>
              </div>
            </>
          )}
          <Button
            title={'task'}
            themeColor={'success'}
            className={'w-full mt-2 flex-fill'}
            onClick={() => {
              setSelectedPanel('Images');
              onSelectedPanel && onSelectedPanel('Images');
              setSelectedMapImageId('');
              toggleActionsMapImageDialog();
            }}
            disabled={!ableUploadImage}>
            <span className={'material-symbols-outlined mx-2'}>route</span>
            Add image
          </Button>
          {!ableUploadImage && (
            <small className={'text-danger'}>
              Please upload illustration map
            </small>
          )}

          {getMapImages().length > 0 &&
            getMapImages()
              .filter(
                (mapImage: MapImage) =>
                  GetResourceValue(state.gameDocument!, mapImage.titleResId!)
                    ?.toLowerCase()
                    .includes(searchImageText.toLowerCase()) ?? mapImage
              )
              .map((mapImage: MapImage, index: number) => (
                <div
                  key={mapImage.id}
                  className={
                    'd-flex w-100 justify-content-between align-items-center mt-1'
                  }>
                  {mapImage.isVisible && (
                    <span
                      onClick={() => showMapImage(mapImage, false)}
                      className={'material-symbols-outlined'}>
                      <span className={'material-symbols-outlined'}>flag</span>
                    </span>
                  )}
                  {!mapImage.isVisible && (
                    <span
                      onClick={() => showMapImage(mapImage, true)}
                      className={'text-danger material-symbols-outlined'}>
                      visibility_off
                    </span>
                  )}
                  <div className={'card w-100 bg-secondary '}>
                    <div
                      className={
                        'card-body p-2 d-flex w-100 justify-content-between align-items-center'
                      }>
                      <span className={'text-light'}>{mapImage.name}</span>
                      <div>
                        <PopupMenu
                          id={index}
                          menus={taskMenu.filter((x) => x.textMenu !== 'Copy')}
                          onMenuSelected={(id, menu) => {
                            handleMapImagesMenuSelected(
                              getMapImages()[index].id!,
                              menu
                            );
                          }}
                          iconClass={
                            'k-icon k-i-more-vertical h5 mb-0 text-light'
                          }
                        />
                      </div>
                    </div>
                  </div>
                </div>
              ))}
        </PanelBarItem>
      </PanelBar>

      {dialogVisible && (
        <ResourceWindow
          toggleDialog={toggleDialog}
          onSubmit={handleFilesSelected}
          acceptedExtension={'image/*'}
          imageUrl={currentImageResource ?? ''}
          imageSource="Game Design | Maps - Illustration Maps"
        />
      )}

      {/* dialog for events and actions */}
      {actionsEventDialogVisible && (
        <WorldMapEvent
          zoneId={zoneId}
          gameDocument={gameDocument}
          title={titleDialog}
          onCancel={() => toggleActionsEventDialogVisible()}
          onConfirm={handleEventChange}
        />
      )}

      {actionsEditTaskDialogVisible && (
        <TaskEditorWindow
          toggleDialog={toggleEditTaskDialog}
          onSubmit={handleTaskEntity}
          onClose={toggleEditTaskDialog}
          editorEntity={entityEditorValue!}
          editorResource={resourceEditorValue!}
          editorMode={'basic'}
          showExistingTask={true}
        />
      )}

      {actionsAreaDialogVisible && (
        <AreaEditorWindow
          editorEntity={{
            isNew: false,
            entity: getAreaByIndex(selectedAreaId)!
          }}
          toggleDialog={toggleActionsAreaDialog}
          onClose={toggleActionsAreaDialog}
          onSubmit={(editorEntity: EntityEditor<AreaEntity>): void => {
            UpdateAreaAsync(
              state.gameDocument!,
              editorEntity.entity.id,
              editorEntity.entity
            )
              .then((response) =>
                setState((prev) => UpdateGameDocState(prev, response))
              )
              .finally(() => {
                toggleActionsAreaDialog();
                setSelectedAreaId(-1);
              });
          }}
          editorMode={'basic'}
        />
      )}

      {actionsRoutesDialogVisible && (
        <RouteEditorWindow
          editorEntity={
            selectedRouteId === ''
              ? {
                  isNew: true,
                  entity: {} as TaskRoute
                }
              : {
                  isNew: false,
                  entity: getRoutes().find(
                    (x) => x.id === selectedRouteId
                  ) as TaskRoute
                }
          }
          routes={getRoutes() ?? []}
          toggleDialog={toggleActionsRouteDialog}
          onClose={toggleActionsRouteDialog}
          onSubmit={async (
            editorEntity: EntityEditor<TaskRoute>
          ): Promise<void> => {
            if (editorEntity.isNew) {
              if (state.gameDocument) {
                editorEntity.entity.id = uuid();
                if (zoneId !== '') {
                  const zoneMapAssetId: string =
                    state.gameDocument?.rules.worldMap.zones.find(
                      (zone: ZoneAssetId) => zone.zoneAssId === zoneId
                    )?.mapAssId ?? '';

                  GetDetailRoutesByMapId(
                    state?.gameDocument!,
                    zoneMapAssetId
                  ).then((routesEntity) => {
                    if (!routesEntity) {
                      routesEntity = {
                        mapId: '',
                        titleResId: '',
                        fromTaskId: '',
                        toTaskId: '',
                        name: '',
                        description: ''
                      };
                    }
                    editorEntity.entity.mapId = zoneMapAssetId;
                    routesEntity = editorEntity.entity;
                    updateRoutesAsset(routesEntity as TaskRoute);
                  });
                } else {
                  getAssetRoutes().then((routesEntity) => {
                    if (!routesEntity) {
                      routesEntity = {};
                    }
                    editorEntity.entity.mapId =
                      state?.gameDocument?.rules?.worldMap?.mapAssId!;
                    routesEntity = editorEntity.entity;
                    updateRoutesAsset(routesEntity as TaskRoute);
                  });
                }
              }
            } else {
              if (state.gameDocument) {
                if (zoneId !== '') {
                  const zoneMapAssetId: string =
                    state.gameDocument?.rules.worldMap.zones.find(
                      (zone: ZoneAssetId) => zone.zoneAssId === zoneId
                    )?.mapAssId ?? '';

                  GetDetailRoutesById(
                    state?.gameDocument!,
                    zoneMapAssetId,
                    editorEntity.entity.id
                  ).then((routeEntity) => {
                    if (!routeEntity) {
                      routeEntity = {
                        mapId: '',
                        titleResId: '',
                        fromTaskId: '',
                        toTaskId: '',
                        name: '',
                        description: ''
                      };
                    }
                    editorEntity.entity.mapId = zoneMapAssetId;
                    routeEntity = editorEntity.entity;
                    updateRoutesAsset(routeEntity as TaskRoute);
                  });
                } else {
                  GetDetailRoutesByRouteId(
                    state?.gameDocument!,
                    editorEntity.entity.id
                  ).then((routeEntity) => {
                    if (!routeEntity) {
                      routeEntity = {
                        mapId: '',
                        titleResId: '',
                        fromTaskId: '',
                        toTaskId: '',
                        name: '',
                        description: ''
                      };
                    }
                    routeEntity = editorEntity.entity;
                    updateRoutesAsset(routeEntity as TaskRoute);
                  });
                }
              }
            }

            toggleActionsRouteDialog();
          }}
          editorMode={'basic'}
          tasks={tasks}
        />
      )}

      {actionsMapImageDialogVisible && (
        <MapImageWindow
          editorEntity={
            selectedMapImageId === ''
              ? {
                  isNew: true,
                  entity: {} as MapImage
                }
              : {
                  isNew: false,
                  entity: getMapImages().find(
                    (x) => x.id === selectedMapImageId
                  ) as MapImage
                }
          }
          editorResource={resourceEditorValue!}
          toggleDialog={toggleActionsMapImageDialog}
          onSubmit={(mapImage: EntityEditor<MapImage>) => {
            getMapEntityAsync().then((responseMap) => {
              let newMapImageId = uuid();
              const map = mapInfo;
              let coordinate = fromLonLat([
                mapInfo?.longitude!,
                mapInfo?.latitude!
              ]);

              let boundary = {
                id: `map-images-${newMapImageId}`,
                type: 'Feature',
                geometry: {
                  id: `map-images-${newMapImageId}`,
                  type: 'Point',
                  coordinates:
                    responseMap?.type === 'illustration'
                      ? [responseMap?.longitude!, responseMap?.latitude!]
                      : (coordinate as any)
                }
              };

              if (mapImage.isNew) {
                const newData: MapImage = {
                  ...mapImage.entity,
                  id: uuid(),
                  mapId: responseMap?.id!,
                  boundary: boundary
                };

                updateMapImageAsset(newData);
              } else {
                const updateData: MapImage = {
                  ...mapImage.entity,
                  id: selectedMapImageId,
                  mapId: responseMap?.id!
                };
                updateMapImageAsset(updateData);
              }
            });
          }}
        />
      )}

      {showDeleteIllustrationConfirm && (
        <YesNoDialog
          title={'Confirm removal'}
          onConfirm={onConfirmDeleteIllustration}
          onClose={() => setShowDeleteIllustrationConfirm(false)}>
          Are you sure you want to remove the image?
        </YesNoDialog>
      )}

      {showDeleteZoneConfirmation && (
        <YesNoDialog
          title={'Confirm removal'}
          onConfirm={onConfirmDeleteZone}
          onClose={() => setShowDeleteZoneConfirmation(false)}>
          Are you sure you want to remove the zone?
        </YesNoDialog>
      )}

      {showDeleteTaskConfirm && (
        <YesNoDialog
          title={'Confirm removal'}
          onConfirm={onConfirmDeleteTask}
          onClose={() => setShowDeleteTaskConfirm(false)}>
          Are you sure you want to remove the task?
        </YesNoDialog>
      )}

      {showDeleteAreaConfirmation && (
        <YesNoDialog
          title={'Confirm removal'}
          onConfirm={onConfirmDeleteArea}
          onClose={() => setShowDeleteAreaConfirmation(false)}>
          Are you sure you want to remove the area?
        </YesNoDialog>
      )}

      {showDeleteRouteConfirmation && (
        <YesNoDialog
          title={'Confirm removal'}
          onConfirm={onConfirmDeleteRoute}
          onClose={toggleRouteDeleteDialog}>
          Are you sure you want to remove the route?
        </YesNoDialog>
      )}
      {showDeleteMapImageConfirmation && (
        <YesNoDialog
          title={'Confirm removal'}
          onConfirm={onConfirmDeleteMapImage}
          onClose={toggleMapImageDeleteDialog}>
          Are you sure you want to remove the image?
        </YesNoDialog>
      )}
    </>
  );
};

export default MapEditor;
