import {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react';
import 'ol/style';
import { fromLonLat, toLonLat } from 'ol/proj';
import { Coordinate } from '../../types/place';
import MapEvent from 'ol/MapEvent';
import MoveMapPad from './map-move-pad';
import { BingMaps, ImageStatic, OSM, Vector as VectorSource } from 'ol/source';
import { Tile as TileLayer, Vector as VectorLayer } from 'ol/layer';
import Draw, { createBox, DrawEvent } from 'ol/interaction/Draw';
import GeoJSON from 'ol/format/GeoJSON';
import { Geometry, LineString, MultiPoint, Point, Polygon } from 'ol/geom';
import { Fill, Icon, Stroke, Style, Text as TextFeature } from 'ol/style';
import { MapImage, Resource } from '../../types/game-document';
import { DragBox, Modify, Select, Translate } from 'ol/interaction';
import CircleStyle from 'ol/style/Circle';
import { Extent, getCenter, getHeight, getWidth } from 'ol/extent';
import {
  click,
  never,
  platformModifierKeyOnly,
  primaryAction
} from 'ol/events/condition';
import Feature, { FeatureLike } from 'ol/Feature';
import Collection from 'ol/Collection';
import { Type } from 'ol/geom/Geometry';
import {
  AreaEntity,
  MapEntity,
  TaskEntity,
  ZoneEntity
} from '../../types/game-document/entities';
import { GetImageStyle } from '../../utils/map-helper';
import cloneDeep from 'lodash.clonedeep';
import { TranslateEvent } from 'ol/interaction/Translate';
import { ZoneAssetId } from '../../types/game-document/entities/zone';
import { useParams } from 'react-router-dom';
import { GameDocumentContext } from '../../contexts/game-document';
import {
  GetResourceValue,
  UpdateGameDocState,
  UpdateMapAsync,
  UpdateTaskAsync
} from '../../utils/game-document';
import { SwitchChangeEvent } from '@progress/kendo-react-inputs';
import { BingMapsKey } from '../../constants/bing-key';
import { TaskRoute } from '../../types/game-document/entities/routes';
import { MapImagePropertyEditorWindow } from '../../features/game-document/maps/map-image-property-editor';
import {
  MAP_OBJECT_ALL,
  MAP_OBJECT_AREA,
  MAP_OBJECT_IMAGE,
  MAP_OBJECT_TASK,
  MAP_OBJECT_ZONE
} from '../../constants/map';
import { MapContext } from './map-context';
import { EventsKey } from 'ol/events';
import { unByKey } from 'ol/Observable';
import { Overlay } from 'ol';
import ImageLayer from 'ol/layer/Image';
import Transform from 'ol-ext/interaction/Transform';
import GeoImage from 'ol-ext/source/GeoImage';
import { ImageOverlay } from '../../types/game-document/entities/map';

export type MapAction = 'Zone' | 'Area' | 'Task' | 'Annotation' | 'Images' | '';

const addTaskMode = {
  id: '00000',
  name: '',
  image: 'https://cdn.catalystglobal.games/resources/map-task.png'
};

interface MapCanvasProps {
  coordinate?: Coordinate;
  onMoveEnd?: (e: MapEvent) => void;
  onAddZone?: (zone: ZoneEntity) => void;
  onAddArea?: (area: AreaEntity) => void;
  areas?: AreaEntity[];
  onAddTask?: (coordinates: [number, number]) => void;
  tasks?: TaskEntity[];
  routes?: TaskRoute[];
  selectedPanel?: MapAction;
  isClearInteractions?: boolean;
  resourceImages?: Resource[];
  onEditZones?: (zones: ZoneEntity) => void;
  onEditAreas?: (areas: AreaEntity) => void;
  onEditTasks?: (tasks: TaskEntity) => void;
  onEditMapImages?: (mapImage: MapImage) => void;
  drawType?: Type;
  isEditZone?: boolean;
  mapInfo?: MapEntity;
  mapImages?: MapImage[];
  onRemoveSelectAllAssets?: () => void;
  selectedObjectType?: 'ALL' | 'ZONE' | 'TASK' | 'TASK' | 'IMAGE' | 'AREA' | '';
  zones?: ZoneEntity[];
  addTaskOverlay: (
    id: string,
    name: string,
    iconUrl: string,
    position: Coordinate | number[],
    isAddTaskMode?: boolean
  ) => void;
  updateTaskOverlayPosition: (
    id: string,
    newPosition: Coordinate | number[]
  ) => void;
  removeTaskOverlay: (id: string) => void;
  imageOverlay?: ImageOverlay;
}

const geojsonObject = {
  type: 'FeatureCollection',
  crs: {
    type: 'name',
    properties: {
      name: 'EPSG:3857'
    }
  },
  features: []
};

const tempSource = new VectorSource({
  features: new GeoJSON().readFeatures(geojsonObject)
});

const zoneStyle = new Style({
  geometry: function (feature) {
    const modifyGeometry = feature.get('modifyGeometry');
    return modifyGeometry ? modifyGeometry.geometry : feature.getGeometry();
  },
  fill: new Fill({
    color: 'rgba(255, 255, 255, 0.2)'
  }),
  stroke: new Stroke({
    color: [255, 85, 39],
    width: 2
  }),
  image: new CircleStyle({
    radius: 7,
    fill: new Fill({
      color: [255, 85, 39]
    })
  })
});

function calculateCenter(geometry: any) {
  let center1: any, coordinates, minRadius;
  const type = geometry.getType();
  if (type === 'Polygon') {
    let x = 0;
    let y = 0;
    let i = 0;
    coordinates = geometry.getCoordinates()[0].slice(1);
    coordinates.forEach(function (coordinate: any) {
      x += coordinate[0];
      y += coordinate[1];
      i++;
    });
    center1 = [x / i, y / i];
  }
  let sqDistances;
  if (coordinates) {
    sqDistances = coordinates.map(function (coordinate: any) {
      const dx = coordinate[0] - center1[0];
      const dy = coordinate[1] - center1[1];
      return dx * dx + dy * dy;
    });
    minRadius = Math.sqrt(Math.max.apply(Math, sqDistances)) / 3;
  } else {
    minRadius =
      Math.max(
        getWidth(geometry.getExtent()),
        getHeight(geometry.getExtent())
      ) / 3;
  }
  return {
    center: center1,
    coordinates: coordinates,
    minRadius: minRadius,
    sqDistances: sqDistances
  };
}

const MapCanvas = ({
  coordinate,
  onMoveEnd,
  onAddZone,
  onAddArea,
  zones,
  areas,
  selectedPanel,
  onAddTask,
  tasks,
  routes,
  resourceImages,
  onEditZones,
  onEditAreas,
  onEditTasks,
  isEditZone,
  mapInfo,
  mapImages,
  addTaskOverlay,
  removeTaskOverlay,
  updateTaskOverlayPosition,
  onEditMapImages = () => {},
  onRemoveSelectAllAssets = () => {},
  selectedObjectType,
  imageOverlay
}: MapCanvasProps) => {
  const map = useContext(MapContext);
  const [state, setState] = useContext(GameDocumentContext);
  const gameDocument = state.gameDocument;
  const [mapLayer, setMapLayer] = useState<any>();
  const [isOpenStreetMap, setIsOpenStreetMap] = useState<boolean>(
    mapInfo?.type === 'openStreetMap' ? true : false
  );
  const { zoneId } = useParams();
  const [selectedImageId, setSelectedImageId] = useState<string>('');
  const [showImageEditor, setShowImageEditor] = useState<boolean>(false);
  const mapRef = useRef<HTMLDivElement>(null);
  map.setTarget(mapRef.current as HTMLElement);
  const selectRef = useRef<Select | null>(null);
  const OBJECT_TYPE = 'objectType';

  const dragBox = new DragBox({
    condition: platformModifierKeyOnly
  });

  useEffect(() => {
    RemoveImageFeature();
    setMapImages();
    if (selectedObjectType) {
      selectAllObject();
    }
  }, [
    map,
    selectedPanel,
    resourceImages,
    JSON.stringify(mapImages),
    mapLayer,
    selectedObjectType
  ]);

  const onAddDragBox = () => {
    let tempBoxLayer: any; // Variable to store the temporary box layer

    const layers = map.getAllLayers();

    map.addInteraction(dragBox);

    const select = new Select({
      condition: click,
      layers: [layers as any] // Use an array with VectorLayer
    });

    map.addInteraction(select);

    selectRef.current = select;

    let allFeatures: Feature[] = [];

    dragBox.on('boxstart', function () {
      // Style for the box at the start of the drag
      const startStyle = new Style({
        stroke: new Stroke({
          color: 'blue',
          width: 2
        }),
        fill: new Fill({
          color: 'rgba(0, 0, 255, 0.1)'
        })
      });

      tempBoxLayer = new VectorLayer({
        source: new VectorSource({
          features: [new Feature(dragBox.getGeometry())]
        }),
        style: startStyle
      });

      map.addLayer(tempBoxLayer);
    });

    dragBox.on('boxdrag', function () {
      // Style for the box during the drag
      const dragStyle = new Style({
        stroke: new Stroke({
          color: '#69a2ff',
          width: 2
        }),
        fill: new Fill({
          color: 'rgba(255, 255, 255, 0.5)'
        })
      });

      tempBoxLayer.setStyle(dragStyle);
    });

    dragBox.on('boxend', function () {
      map.removeLayer(tempBoxLayer);
      map.getInteractions().forEach((interaction) => {
        if (interaction instanceof Select) {
          map.removeInteraction(interaction);
        }

        if (interaction instanceof Translate) {
          map.removeInteraction(interaction);
        }
      });

      tempBoxLayer = undefined;

      const boxExtent = dragBox.getGeometry().getExtent();

      map.getLayers().forEach((layer) => {
        if (layer instanceof VectorLayer) {
          const source = layer.getSource();
          if (source instanceof VectorSource) {
            // Collect features from the source
            const features = source.getFeatures();

            if (selectedObjectType === MAP_OBJECT_ALL) {
              allFeatures.push(...features);
            } else {
              if (layer.get('name') === selectedObjectType) {
                // Filter features based on the selected object type
                const filteredFeatures = features.filter((feature) => {
                  const objectType = feature.get(OBJECT_TYPE);
                  return objectType === selectedObjectType;
                });

                allFeatures.push(...filteredFeatures);
              }
            }
          }
        }
      });

      const filteredFeatures = allFeatures.filter((feature) => {
        const featureGeometry = feature.getGeometry();
        if (featureGeometry) {
          return intersectsExtent(featureGeometry.getExtent(), boxExtent);
        }
        return false;
      });

      select.getFeatures().clear();
      select.getFeatures().extend(filteredFeatures);

      // Create a Translate interaction
      const translate = new Translate({
        features: select.getFeatures()
      });

      // Add the Translate interaction to the map
      map.addInteraction(translate);

      // Handle the "translateend" event
      translate.on('translateend', (event) => {
        // Explicitly convert Collection to Feature array
        const translatedFeatures =
          event.features.getArray() as Feature<Geometry>[];

        translatedFeatures?.forEach((element) => {
          let objectType = element.get(OBJECT_TYPE);
          let lastPoint = element.getGeometry();
          let featureId = element.getId()?.toString() ?? '';

          if (objectType === MAP_OBJECT_ZONE) {
            updateFeatureZone(
              featureId,
              element.getGeometry() as Geometry,
              lastPoint as Geometry
            );
          } else if (objectType === MAP_OBJECT_TASK) {
            updateFeatureTask(
              featureId,
              element.getGeometry() as Geometry,
              lastPoint as Geometry
            );
          } else if (objectType === MAP_OBJECT_IMAGE) {
            updateFeatureImages(
              featureId,
              element.getGeometry() as Geometry,
              lastPoint as Geometry
            );
          } else if (objectType === MAP_OBJECT_AREA) {
            updateFeatureArea(
              featureId,
              element.getGeometry() as Geometry,
              lastPoint as Geometry
            );
          }
        });

        // Remove the select and translate interactions when done
        map.removeInteraction(select);
        map.removeInteraction(translate);
      });
    });
  };

  function intersectsExtent(featureExtent: any, boxExtent: any) {
    return (
      featureExtent[0] < boxExtent[2] &&
      featureExtent[2] > boxExtent[0] &&
      featureExtent[1] < boxExtent[3] &&
      featureExtent[3] > boxExtent[1]
    );
  }

  const selectAllObject = () => {
    const layers = map.getAllLayers();

    const select = new Select({
      condition: click,
      layers: [layers as any] // Use an array with VectorLayer
    });

    map.addInteraction(select);

    selectRef.current = select;

    const allFeatures: Feature[] = [];

    map.getLayers().forEach((layer) => {
      if (layer instanceof VectorLayer) {
        const source = layer.getSource();
        if (source instanceof VectorSource) {
          // Collect features from the source
          const features = source.getFeatures();

          if (selectedObjectType === MAP_OBJECT_ALL) {
            allFeatures.push(...features);
          } else {
            features?.forEach((feature) => {
              let objectType = feature.get(OBJECT_TYPE);
              if (objectType === selectedObjectType) {
                allFeatures.push(feature);
              }
            });
          }
        }
      }
    });

    select.getFeatures().clear();
    select.getFeatures().extend(allFeatures);

    // Create a Translate interaction
    const translate = new Translate({
      features: select.getFeatures()
    });

    // Add the Translate interaction to the map
    map.addInteraction(translate);

    const startPixels = { x: 0, y: 0 };
    const overlays: Overlay[] = [];
    const updatedOverlayPositions: [number, number][] = [];
    if (selectedObjectType === 'ALL') {
      map
        .getOverlays()
        .getArray()
        .forEach((ov) => overlays.push(ov));
    }

    translate.on('translatestart', (event) => {
      const translatedFeature =
        event.features.getArray()[0] as Feature<Geometry>;
      const geometry = translatedFeature.getGeometry();
      const startFeaturePosition = map.getPixelFromCoordinate(
        (geometry as Polygon).getFirstCoordinate()
      );
      startPixels.x = startFeaturePosition[0];
      startPixels.y = startFeaturePosition[1];
    });

    translate.on('translating', (event) => {
      const translatedFeature =
        event.features.getArray()[0] as Feature<Geometry>;
      const geometry = translatedFeature.getGeometry();
      const newFeaturePosition = map.getPixelFromCoordinate(
        (geometry as Polygon).getFirstCoordinate()
      );

      const deltaX = newFeaturePosition[0] - startPixels.x;
      const deltaY = newFeaturePosition[1] - startPixels.y;

      overlays.forEach((ov, idx) => {
        const currentCoordinate = ov.getPosition();

        if (currentCoordinate) {
          const currentPixel = map.getPixelFromCoordinate(currentCoordinate);
          const newPixelPosition = [
            currentPixel[0] + deltaX,
            currentPixel[1] + deltaY
          ];
          const newCoordinatePosition =
            map.getCoordinateFromPixel(newPixelPosition);
          ov.setPosition(newCoordinatePosition);
          updatedOverlayPositions[idx] = [
            newCoordinatePosition[0],
            newCoordinatePosition[1]
          ];
        }
      });

      startPixels.x = newFeaturePosition[0];
      startPixels.y = newFeaturePosition[1];
    });

    // Handle the "translateend" event
    translate.on('translateend', (event) => {
      // Explicitly convert Collection to Feature array
      const translatedFeatures =
        event.features.getArray() as Feature<Geometry>[];

      translatedFeatures?.forEach((element) => {
        let objectType = element.get(OBJECT_TYPE);
        let lastPoint = element.getGeometry();
        let featureId = element.getId()?.toString() ?? '';

        if (objectType === MAP_OBJECT_ZONE) {
          updateFeatureZone(
            featureId,
            element.getGeometry() as Geometry,
            lastPoint as Geometry
          );
        } else if (objectType === MAP_OBJECT_TASK) {
          updateFeatureTask(
            featureId,
            element.getGeometry() as Geometry,
            lastPoint as Geometry
          );
        } else if (objectType === MAP_OBJECT_IMAGE) {
          updateFeatureImages(
            featureId,
            element.getGeometry() as Geometry,
            lastPoint as Geometry
          );
        } else if (objectType === MAP_OBJECT_AREA) {
          updateFeatureArea(
            featureId,
            element.getGeometry() as Geometry,
            lastPoint as Geometry
          );
        }
      });

      if (tasks && updatedOverlayPositions.length > 0) {
        overlays.forEach((ov, idx) => {
          const taskId = ov.getId();
          if (taskId) {
            const selectedTask = tasks.find((task) => task.id === taskId);
            if (gameDocument && selectedTask && selectedTask.boundary) {
              selectedTask.boundary.geometry.coordinates =
                updatedOverlayPositions[idx];

              UpdateTaskAsync(
                gameDocument,
                taskId as string,
                selectedTask
              ).then((response) => {
                setState((prev) => UpdateGameDocState(prev, response));
              });
            }
          }
        });
      }

      // Remove the select and translate interactions when done
      map.removeInteraction(select);
      map.removeInteraction(translate);
    });
  };

  useEffect(() => {
    if (selectedObjectType === '') {
      onRemoveSelectAllAssets();
    } else {
      selectAllObject();
      onAddDragBox();
    }
  }, [map, selectedObjectType]);

  useEffect(() => {
    RemoveLineFeature();

    if (routes && routes.length > 0) {
      routes.forEach((item) => {
        // Get Location start task and end task - Latitude and Longitude
        const startCoordinate =
          tasks?.find((i) => i.id === item.fromTaskId)?.boundary?.geometry
            ?.coordinates ?? [];
        const endCoordinate =
          tasks?.find((i) => i.id === item.toTaskId)?.boundary?.geometry
            ?.coordinates ?? [];
        if (startCoordinate.length > 0 && endCoordinate.length > 0) {
          setLineString(item.id, startCoordinate, endCoordinate);
        }

        map.getView();
      });
    }
  }, [routes, tasks]);

  const moveEndRef = useRef<((event: MapEvent) => void) | null>(null);
  const handleMoveEnd = useCallback(
    (e: MapEvent) => {
      if (onMoveEnd) {
        onMoveEnd(e);
      }
    },
    [gameDocument, onMoveEnd]
  );

  useEffect(() => {
    moveEndRef.current = handleMoveEnd;
  }, [handleMoveEnd]);

  useEffect(() => {
    const endListener = (e: MapEvent) => {
      if (moveEndRef.current) {
        moveEndRef.current(e);
      }
    };

    map.on('moveend', endListener);
    let currentMapEntity: MapEntity;
    if (zoneId) {
      const mapZone = gameDocument?.rules.worldMap.zones.find(
        (x) => x.zoneAssId === zoneId
      )!;
      currentMapEntity = gameDocument?.assets?.maps?.find(
        (x) => x.id === mapZone?.mapAssId
      )!;
    } else {
      currentMapEntity = gameDocument?.assets?.maps?.find(
        (x) => x.name === 'world-map'
      )!;
    }

    map.on('click', function (event) {
      onRemoveSelectAllAssets();
    });

    currentMapEntity &&
      setIsOpenStreetMap(
        currentMapEntity.type === 'openStreetMap' ? true : false
      );
    const mapViewType = zoneId ? currentMapEntity.type : mapInfo?.type;

    const tileLayer = new TileLayer({
      source:
        mapViewType && mapViewType === 'openStreetMap'
          ? new OSM()
          : new BingMaps({
              key: BingMapsKey,
              imagerySet: 'Aerial',
              maxZoom: 19, // use maxZoom 19 to see stretched tiles instead of the BingMaps
              placeholderTiles: true
              // "no photos at this zoom level" tiles
            })
    });

    const layers = map.getAllLayers();
    if (layers.length > 0) {
      layers[0] = tileLayer;
      map.setLayers(layers);
    } else {
      map.setLayers([tileLayer]);
    }
    setIsOpenStreetMap(
      (
        zoneId
          ? currentMapEntity.type === 'openStreetMap'
          : mapInfo?.type === 'openStreetMap'
      )
        ? true
        : false
    );

    setMapLayer(layers);
    return () => {
      map.un('moveend', endListener);
    };
  }, []);

  useEffect(() => {
    let currentMapEntity: MapEntity;
    if (zoneId) {
      const mapZone = gameDocument?.rules.worldMap.zones.find(
        (x) => x.zoneAssId === zoneId
      )!;
      currentMapEntity = gameDocument?.assets?.maps?.find(
        (x) => x.id === mapZone?.mapAssId
      )!;
    } else {
      currentMapEntity = gameDocument?.assets?.maps?.find(
        (x) => x.name === 'world-map'
      )!;
    }
    currentMapEntity &&
      setIsOpenStreetMap(
        currentMapEntity.type === 'openStreetMap' ? true : false
      );
    const mapViewType = currentMapEntity.type;
    const tileLayer = new TileLayer({
      source:
        mapViewType && mapViewType === 'openStreetMap'
          ? new OSM()
          : new BingMaps({
              key: BingMapsKey,
              imagerySet: 'Aerial',
              maxZoom: 19, // use maxZoom 19 to see stretched tiles instead of the BingMaps
              placeholderTiles: true
              // "no photos at this zoom level" tiles
            })
    });

    const layers = map.getAllLayers();
    if (layers.length > 0) {
      layers[0] = tileLayer;
      map.setLayers(layers);
    } else {
      map.setLayers([tileLayer]);
    }
    setIsOpenStreetMap(
      currentMapEntity.type === 'openStreetMap' ? true : false
    );
  }, []);

  useEffect(() => {
    if (isEditZone && zones && mapLayer) {
      const feature = new GeoJSON().readFeature(zones[0].boundary as never);
      const fitOptions = { duration: 1000 };
      if (feature instanceof Feature) {
        const geo = feature.getGeometry() as any;
        if (geo) {
          map.getView().fit(geo, fitOptions);
        }
      }
    }
  }, [JSON.stringify(zones), isEditZone, mapLayer]);

  useEffect(() => {
    if (!isEditZone && coordinate && mapLayer) {
      map.getView().setCenter(fromLonLat([coordinate.lng, coordinate.lat]));
    }
  }, [coordinate, mapLayer]);

  const updateFeatureTask = (
    id: string,
    geometry: Geometry,
    lastPoint: Geometry
  ) => {
    if (tasks) {
      let newTasks: TaskEntity[] = cloneDeep(tasks);
      let data = lastPoint as Point;
      let coordinate = data.getCoordinates();

      let item = newTasks.find((x) => x.boundary?.id === id);

      let index = -1;

      let selectedIndex = newTasks.findIndex((x) => x.boundary?.id === id);

      if (item && item.boundary) {
        item.boundary.geometry.coordinates = [
          coordinate[0],
          coordinate[1]
        ] as any;

        newTasks[index] = item;
        if (onEditTasks) {
          onEditTasks(newTasks[selectedIndex]);
        }
      }
    }
  };

  const updateFeatureZone = (
    id: string,
    geometry: Geometry,
    lastPoint: Geometry
  ) => {
    if (zones) {
      let dataPolygon = geometry as Polygon;
      let coordinatePolygon = dataPolygon.getCoordinates();

      let itemPolygon = zones.find((x) => x.boundary?.id === id);
      if (itemPolygon && itemPolygon.boundary) {
        itemPolygon.boundary.geometry.coordinates = coordinatePolygon as any;
      }

      let selectedIndex = zones.findIndex((x) => x.boundary?.id === id);

      if (onEditZones && zones) {
        onEditZones(zones[selectedIndex]);
      }
    }
  };

  useEffect(() => {
    if (mapLayer) {
      const areaFeatures: Feature<Geometry>[] = [];

      //#region populate area features from areas Props
      if (areas) {
        let worldAreas = areas.filter((x) => x.isVisible);
        worldAreas.forEach((worldArea) => {
          const geoJsonFeatures = new GeoJSON().readFeature(
            worldArea.boundary as never
          );

          if (geoJsonFeatures instanceof Feature) {
            geoJsonFeatures.setStyle((feature) => {
              const styles = [zoneStyle];
              const modifyGeometry = feature.get('modifyGeometry');
              const geometry = modifyGeometry
                ? modifyGeometry.geometry
                : feature.getGeometry();
              const result = calculateCenter(geometry);
              const center = result.center;
              if (center) {
                const coordinates = result.coordinates;
                if (coordinates) {
                  const minRadius = result.minRadius;
                  const sqDistances = result.sqDistances;
                  const rsq = minRadius * minRadius;
                  const points = coordinates.filter(function (
                    coordinate: any,
                    index: any
                  ) {
                    return sqDistances[index] > rsq;
                  });
                  styles.push(
                    new Style({
                      geometry: new MultiPoint(points),
                      image: new CircleStyle({
                        radius: 4,
                        fill: new Fill({
                          color: [255, 85, 39]
                        })
                      })
                    })
                  );
                }
              }

              return styles;
            });

            geoJsonFeatures.set(OBJECT_TYPE, MAP_OBJECT_AREA);

            areaFeatures.push(geoJsonFeatures);
          }
        });
      }
      //#endregion

      //#region create area vector source & area layer
      const areaSources = new VectorSource({
        features: areaFeatures
      });

      const areaLayer = new VectorLayer({
        source: areaSources
      });

      areaLayer.set('name', MAP_OBJECT_AREA);

      map.addLayer(areaLayer);
      //#endregion

      //#region create Modify() to handle area resize
      const areaModify = new Modify({
        hitDetection: areaLayer,
        source: areaSources,
        condition: function (event) {
          return primaryAction(event) && !platformModifierKeyOnly(event);
        },
        deleteCondition: never,
        insertVertexCondition: never,
        style: function (feature: FeatureLike) {
          feature.get('features').forEach(function (modifyFeature: any) {
            const modifyGeometry = modifyFeature.get('modifyGeometry');
            if (modifyGeometry) {
              let ft = (feature as Feature).getGeometry() as Point;
              const point = ft.getCoordinates();
              let modifyPoint = modifyGeometry.point;
              if (!modifyPoint) {
                // save the initial geometry and vertex position
                modifyPoint = point;
                modifyGeometry.point = modifyPoint;
                modifyGeometry.geometry0 = modifyGeometry.geometry;
                // get anchor and minimum radius of vertices to be used
                const result = calculateCenter(modifyGeometry.geometry0);
                modifyGeometry.center = result.center;
                modifyGeometry.minRadius = result.minRadius;
              }

              const center = modifyGeometry.center;
              const minRadius = modifyGeometry.minRadius;
              let dx, dy;
              if (modifyPoint.length > 0 && center) {
                dx = modifyPoint[0] - center[0];
                dy = modifyPoint[1] - center[1];
                const initialRadius = Math.sqrt(dx * dx + dy * dy);
                if (initialRadius > minRadius) {
                  dx = point[0] - center[0];
                  dy = point[1] - center[1];
                  const currentRadius = Math.sqrt(dx * dx + dy * dy);
                  if (currentRadius > 0) {
                    const geometry = modifyGeometry.geometry0.clone();
                    geometry.scale(
                      currentRadius / initialRadius,
                      undefined,
                      center
                    );
                    modifyGeometry.geometry = geometry;
                  }
                }
              }
            }
          });
        }
      });

      const modifyStartEventHandler = (event: any) => {
        let features = event.features as Collection<Feature>;

        features.forEach(function (feature) {
          feature.set(
            'modifyGeometry',
            { geometry: feature.getGeometry()?.clone() },
            true
          );
        });
      };

      const areaModifyEndEventHandler = (event: any) => {
        let features = event.features as Collection<Feature>;
        features.forEach(function (feature: Feature) {
          const modifyGeometry = feature.get('modifyGeometry');
          if (modifyGeometry) {
            let lastPoint = feature.getGeometry();
            let featureId = feature.getId()?.toString() ?? '';

            updateFeatureArea(
              featureId,
              modifyGeometry.geometry as Geometry,
              lastPoint as Geometry
            );
          }
        });
      };

      areaModify.on('modifystart', modifyStartEventHandler);
      areaModify.on('modifyend', areaModifyEndEventHandler);

      map.addInteraction(areaModify);

      //#endregion

      //#region create Select() to handle area drag and drop
      const select = new Select({ layers: [areaLayer] });
      let fts = select.getFeatures();

      const translate = new Translate({
        features: fts
      });

      const translateStartHandler = (evt: TranslateEvent) => {
        evt.features.forEach((feat) => {});
      };

      const translateEndHandler = (evt: TranslateEvent) => {
        evt.features.forEach((feat) => {
          let lastPoint = feat.getGeometry();
          let featureId = feat.getId()?.toString() ?? '';
          updateFeatureArea(
            featureId,
            feat.getGeometry() as Geometry,
            lastPoint as Geometry
          );
        });
      };

      translate.on('translatestart', translateStartHandler);
      translate.on('translateend', translateEndHandler);

      if (selectedPanel === '') {
        map.addInteraction(select);
      }

      map.addInteraction(translate);

      //#endregion

      //#region add Draw Event to handle add new area
      let drawArea: any;

      const drawAreaHandler = (event: DrawEvent) => {
        if (onAddArea) {
          if (event && event.feature) {
            let data = event.feature.getGeometry() as Polygon;
            let coordinates = data.getCoordinates();

            onAddArea({
              id: '',
              name: '',
              description: '',
              boundary: {
                id: '',
                type: 'Polygon',
                geometry: coordinates as any
              }
            });
          }
        }
      };

      if (selectedPanel === 'Area') {
        let geometryFunction;

        drawArea = new Draw({
          source: tempSource,
          type: 'Polygon',
          geometryFunction: geometryFunction
        });

        drawArea.on('drawend', drawAreaHandler);

        map.addInteraction(drawArea);
      }
      //#endregion

      if (selectedObjectType) {
        selectAllObject();
      }

      //#region remove event listener, interaction, layer, when unmount
      return () => {
        areaModify.un('modifystart', modifyStartEventHandler);
        areaModify.un('modifyend', areaModifyEndEventHandler);
        translate.un('translateend', translateEndHandler);
        translate.un('translatestart', translateStartHandler);
        if (selectedPanel === 'Area') {
          drawArea.un('drawend', drawAreaHandler);
          map.removeInteraction(drawArea);
        }
        map.removeInteraction(areaModify);
        map.removeInteraction(select);

        map.removeLayer(areaLayer);
      };
      //#endregion
    }
  }, [map, mapLayer, selectedPanel, selectedObjectType, JSON.stringify(areas)]);

  useEffect(() => {
    if (!isEditZone && mapLayer) {
      const zoneFeatures: Feature<Geometry>[] = [];

      //#region populate zone features from zones Props
      if (zones) {
        let worldZones = zones.filter((x) => x.isVisible);
        worldZones.forEach((worldZone) => {
          const geoJsonFeatures = new GeoJSON().readFeature(
            worldZone.boundary as never
          );
          // geoJsonFeatures.setGeometryName(`zone-${worldZone?.id}`);

          if (geoJsonFeatures instanceof Feature) {
            geoJsonFeatures.setStyle((feature) => {
              const styles = [zoneStyle];
              const modifyGeometry = feature.get('modifyGeometry');
              const geometry = modifyGeometry
                ? modifyGeometry.geometry
                : feature.getGeometry();
              const result = calculateCenter(geometry);
              const center = result.center;
              if (center) {
                const coordinates = result.coordinates;
                if (coordinates) {
                  const minRadius = result.minRadius;
                  const sqDistances = result.sqDistances;
                  const rsq = minRadius * minRadius;
                  const points = coordinates.filter(function (
                    coordinate: any,
                    index: any
                  ) {
                    return sqDistances[index] > rsq;
                  });
                  styles.push(
                    new Style({
                      geometry: new MultiPoint(points),
                      image: new CircleStyle({
                        radius: 4,
                        fill: new Fill({
                          color: [255, 85, 39]
                        })
                      })
                    })
                  );
                }
              }

              return styles;
            });

            geoJsonFeatures.set('objectType', MAP_OBJECT_ZONE);

            zoneFeatures.push(geoJsonFeatures);
          }
        });
      }
      //#endregion

      //#region create zone vector source & zone layer
      const zoneSource = new VectorSource({
        features: zoneFeatures
      });

      const zoneLayer = new VectorLayer({
        source: zoneSource
      });

      zoneLayer.set('name', MAP_OBJECT_ZONE);

      map.addLayer(zoneLayer);

      //#endregion

      //#region create Modify() to handle zone resize
      const zoneModify = new Modify({
        hitDetection: zoneLayer,
        source: zoneSource,
        condition: function (event) {
          return primaryAction(event) && !platformModifierKeyOnly(event);
        },
        deleteCondition: never,
        insertVertexCondition: never,
        style: function (feature: FeatureLike) {
          feature.get('features').forEach(function (modifyFeature: any) {
            const modifyGeometry = modifyFeature.get('modifyGeometry');
            if (modifyGeometry) {
              let ft = (feature as Feature).getGeometry() as Point;
              const point = ft.getCoordinates();
              let modifyPoint = modifyGeometry.point;
              if (!modifyPoint) {
                // save the initial geometry and vertex position
                modifyPoint = point;
                modifyGeometry.point = modifyPoint;
                modifyGeometry.geometry0 = modifyGeometry.geometry;
                // get anchor and minimum radius of vertices to be used
                const result = calculateCenter(modifyGeometry.geometry0);
                modifyGeometry.center = result.center;
                modifyGeometry.minRadius = result.minRadius;
              }

              const center = modifyGeometry.center;
              const minRadius = modifyGeometry.minRadius;
              let dx, dy;
              if (modifyPoint.length > 0 && center) {
                dx = modifyPoint[0] - center[0];
                dy = modifyPoint[1] - center[1];
                const initialRadius = Math.sqrt(dx * dx + dy * dy);
                if (initialRadius > minRadius) {
                  dx = point[0] - center[0];
                  dy = point[1] - center[1];
                  const currentRadius = Math.sqrt(dx * dx + dy * dy);
                  if (currentRadius > 0) {
                    const geometry = modifyGeometry.geometry0.clone();
                    geometry.scale(
                      currentRadius / initialRadius,
                      undefined,
                      center
                    );
                    modifyGeometry.geometry = geometry;
                  }
                }
              }
            }
          });
        }
      });

      const modifyStartEventHandler = (event: any) => {
        let features = event.features as Collection<Feature>;

        features.forEach(function (feature) {
          feature.set(
            'modifyGeometry',
            { geometry: feature.getGeometry()?.clone() },
            true
          );
        });
      };

      const zoneModifyEndEventHandler = (event: any) => {
        let features = event.features as Collection<Feature>;
        features.forEach(function (feature: Feature) {
          const modifyGeometry = feature.get('modifyGeometry');
          if (modifyGeometry) {
            let lastPoint = feature.getGeometry();
            let featureId = feature.getId()?.toString() ?? '';

            updateFeatureZone(
              featureId,
              modifyGeometry.geometry as Geometry,
              lastPoint as Geometry
            );
          }
        });
      };

      zoneModify.on('modifystart', modifyStartEventHandler);
      zoneModify.on('modifyend', zoneModifyEndEventHandler);

      map.addInteraction(zoneModify);

      //#endregion

      //#region create Select() to handle zone drag and drop
      const select = new Select({ layers: [zoneLayer] });
      let fts = select.getFeatures();

      const translate = new Translate({
        features: fts
      });

      const translateStartHandler = (evt: TranslateEvent) => {
        evt.features.forEach((feat) => {});
      };

      const translateEndHandler = (evt: TranslateEvent) => {
        evt.features.forEach((feat) => {
          let lastPoint = feat.getGeometry();
          let featureId = feat.getId()?.toString() ?? '';
          updateFeatureZone(
            featureId,
            feat.getGeometry() as Geometry,
            lastPoint as Geometry
          );
        });
      };

      translate.on('translatestart', translateStartHandler);
      translate.on('translateend', translateEndHandler);

      if (selectedPanel === '') {
        map.addInteraction(select);
      }

      map.addInteraction(translate);

      //#endregion

      //#region add Draw Event to handle add new zone
      let drawZone: any;

      const drawZoneHandler = (event: DrawEvent) => {
        if (onAddZone) {
          if (event && event.feature) {
            let data = event.feature.getGeometry() as Polygon;
            let coordinates = data.getCoordinates();

            onAddZone({
              id: '',
              name: '',
              description: '',
              boundary: {
                id: '',
                type: 'Polygon',
                geometry: coordinates as any
              }
            });
          }
        }
      };

      if (selectedPanel === 'Zone') {
        let geometryFunction;

        geometryFunction = createBox();

        drawZone = new Draw({
          source: tempSource,
          type: 'Circle',
          geometryFunction: geometryFunction
        });

        drawZone.on('drawend', drawZoneHandler);

        map.addInteraction(drawZone);
      }
      //#endregion

      if (selectedObjectType) {
        selectAllObject();
      }

      //#region remove event listener, interaction, layer, when unmount
      return () => {
        zoneModify.un('modifystart', modifyStartEventHandler);
        zoneModify.un('modifyend', zoneModifyEndEventHandler);
        translate.un('translateend', translateEndHandler);
        translate.un('translatestart', translateStartHandler);
        if (selectedPanel === 'Zone') {
          drawZone.un('drawend', drawZoneHandler);
          map.removeInteraction(drawZone);
        }
        map.removeInteraction(zoneModify);
        map.removeInteraction(select);

        map.removeLayer(zoneLayer);
      };
      //#endregion
    }
  }, [
    isEditZone,
    map,
    selectedPanel,
    JSON.stringify(zones),
    mapLayer,
    selectedObjectType,
    mapImages
  ]);

  const updateFeatureArea = (
    id: string,
    geometry: Geometry,
    lastPoint: Geometry
  ) => {
    if (areas) {
      let dataPolygon = geometry as Polygon;
      let coordinatePolygon = dataPolygon.getCoordinates();

      let itemPolygon = areas.find((x) => x.boundary?.id === id);
      if (itemPolygon && itemPolygon.boundary) {
        itemPolygon.boundary.geometry.coordinates = coordinatePolygon as any;
      }

      let selectedIndex = areas.findIndex((x) => x.boundary?.id === id);

      if (onEditAreas && areas) {
        onEditAreas(areas[selectedIndex]);
      }
    }
  };

  const setMapImages = () => {
    if (map) {
      mapImages
        ?.filter((x) => x.isVisible)
        ?.forEach((image) => {
          try {
            let lineName = `map-image-${image.id}`;
            RemoveLineFeatureByName(lineName);
            let points = image?.boundary?.geometry?.coordinates as any;

            let lineBetweenTwoFeatures = new Feature<Geometry>({
              geometry: new Point(points as any),
              name: lineName,
              id: image.id
            });

            lineBetweenTwoFeatures.setId(image?.id);

            lineBetweenTwoFeatures.set(OBJECT_TYPE, MAP_OBJECT_IMAGE);

            lineBetweenTwoFeatures.setStyle(
              GetImageStyle(
                GetResourceValue(state?.gameDocument!, image?.imageResId!) ??
                  '',
                GetResourceValue(state?.gameDocument!, image?.titleResId!) ??
                  '',
                image?.opacity! / 100,
                map.getView().getResolution(),
                (image?.imageScale ?? 25) / 100
              )
            );

            let source = new VectorSource({
              features: [lineBetweenTwoFeatures],
              wrapX: false
            });

            const taskLineLayer = new VectorLayer({
              source: source
            });

            taskLineLayer.set('name', MAP_OBJECT_IMAGE);

            if (mapImages && mapImages.length > 0) {
              map.addLayer(taskLineLayer);
            } else {
              map.removeLayer(taskLineLayer);
            }

            //#region create Modify() to handle task drag and drop
            const mapImageModify = new Modify({
              hitDetection: taskLineLayer,
              source: source
            });
            const modifyStartEventHandler = (event: any) => {
              let features = event.features as Collection<Feature>;
              features.forEach(function (feature) {
                feature.set(
                  'modifyGeometry',
                  { geometry: feature.getGeometry()?.clone() },
                  true
                );
              });
            };

            const taskModifyEndEventHandler = (event: any) => {
              let features = event.features as Collection<Feature>;
              features.forEach(function (feature: Feature) {
                const modifyGeometry = feature.get('modifyGeometry');
                if (modifyGeometry) {
                  let lastPoint = feature.getGeometry();
                  let featureId = feature.getId()?.toString() ?? '';
                  updateFeatureImages(
                    featureId,
                    modifyGeometry.geometry as Geometry,
                    lastPoint as Geometry
                  );
                }
              });
            };

            // Create a Select interaction for handling click events
            const selectInteraction = new Select({
              layers: [taskLineLayer],
              condition: click
            });

            selectInteraction.on('select', (event) => {
              if (event.selected.length > 0) {
                const selectedFeature = event.selected[0];
                // Handle the click event for the selected feature
                // You can access the feature properties using selectedFeature.getProperties()
                // For example, selectedFeature.getId() to get the feature ID

                let id = (
                  selectedFeature.getProperties()?.name as string
                )?.split('map-image-');

                setSelectedImageId(id[1]);
                setShowImageEditor(!showImageEditor);
              }
            });

            mapImageModify.on('modifystart', modifyStartEventHandler);
            mapImageModify.on('modifyend', taskModifyEndEventHandler);
            map.addInteraction(mapImageModify);
            map.addInteraction(selectInteraction);
          } catch (error) {}
        });

      //#endregion
    }
  };

  const updateFeatureImages = (
    id: string,
    geometry: Geometry,
    lastPoint: Geometry
  ) => {
    if (mapImages) {
      let newImages: MapImage[] = cloneDeep(mapImages ?? []);
      let data = lastPoint as Point;
      let coordinate = data.getCoordinates();
      let item = newImages.find((x) => x.id === id);
      let index = -1;
      let selectedIndex = newImages.findIndex((x) => x?.id === id);
      if (item && item.boundary) {
        item.boundary.geometry.coordinates = [
          coordinate[0],
          coordinate[1]
        ] as any;
        newImages[index] = item;
        onEditMapImages(newImages[selectedIndex]);
      }
    }
  };

  const setLineString = (
    id: string,
    startCoordinate: number[],
    endCoordinate: number[]
  ) => {
    let lineName = `Line between task-${id}`;

    RemoveLineFeatureByName(lineName);

    let points = [startCoordinate, endCoordinate];

    let lineBetweenTwoFeatures = new Feature({
      geometry: new LineString(points),
      name: lineName
    });

    // Calculate the mid-point of the line
    let midPoint = [
      (startCoordinate[0] + endCoordinate[0]) / 2,
      (startCoordinate[1] + endCoordinate[1]) / 2
    ];

    // Calculate the rotation angle to make the arrow point towards the end coordinate
    let rotation = Math.atan2(
      endCoordinate[1] - startCoordinate[1],
      endCoordinate[0] - startCoordinate[0]
    );
    rotation = (rotation - Math.PI / 2) * -1;

    // Create a new arrow feature
    let arrowFeature = new Feature(new Point(midPoint));

    // Create a style for the arrow
    let arrowStyle = new Style({
      image: new Icon({
        src: '/navigation.png',
        scale: 0.07, // Adjust the scale as needed
        rotateWithView: true,
        rotation: rotation
      })
    });

    // Create a text feature for the title
    const titleFeature = new Feature({
      geometry: new Point(midPoint)
    });

    // Create a style for the arrow
    let textStyle = new Style({
      text: new TextFeature({
        text: routes?.find((x) => x.id === id)?.titleResId! ?? '',
        offsetY: -20, // Adjust the offset to position the title
        font: 'bold 18px Calibri,sans-serif',
        fill: new Fill({
          color: 'black'
        })
      })
    });

    titleFeature.setStyle(textStyle as any);

    // Set the style to the arrow feature
    arrowFeature.setStyle(arrowStyle);

    let source = new VectorSource({
      features: [lineBetweenTwoFeatures, arrowFeature as any, titleFeature],
      wrapX: false
    });

    const taskLayer = new VectorLayer({
      source: source,
      style: [
        new Style({
          stroke: new Stroke({
            color: '#408CBB',
            width: 5,
            lineCap: 'butt'
          })
        }),
        arrowStyle
      ]
    });

    if (routes && routes.length > 0) {
      map.addLayer(taskLayer);
    } else {
      map.removeLayer(taskLayer);
    }
  };

  const RemoveLineFeature = () => {
    const layers = map.getLayers().getArray();

    for (const layer of layers) {
      if (layer instanceof VectorLayer) {
        const source = layer.getSource();
        if (source) {
          const features = source.getFeatures();

          for (const feature of features) {
            let featureName = feature.get('name') as string;

            if (featureName && featureName.includes('Line between task-')) {
              map.removeLayer(layer);
            }
          }
        }
      }
    }
  };

  const RemoveImageFeature = () => {
    const layers = map.getLayers().getArray();

    for (const layer of layers) {
      if (layer instanceof VectorLayer) {
        const source = layer.getSource();
        if (source) {
          const features = source.getFeatures();

          for (const feature of features) {
            let featureName = feature.get('name') as string;

            if (featureName && featureName.includes('map-image-')) {
              map.removeLayer(layer);
            }
          }
        }
      }
    }
  };

  const RemoveLineFeatureByName = (name: string) => {
    const layers = map.getLayers().getArray();

    for (const layer of layers) {
      if (layer instanceof VectorLayer) {
        const source = layer.getSource();
        if (source) {
          const features = source.getFeatures();

          for (const feature of features) {
            let featureName = feature.get('name') as string;

            if (featureName && featureName === name) {
              map.removeLayer(layer);
            }
          }
        }
      }
    }
  };

  /**
   *
   * @param e Street view option true/false
   */
  const onViewChange = (e: SwitchChangeEvent) => {
    const mapTypeChange = e.target.value;
    const mapType = mapTypeChange ? 'openStreetMap' : 'satelliteMap';

    setIsOpenStreetMap(mapTypeChange); //true openStreetMap : satelliteMap
    const tileLayer = new TileLayer({
      source: mapTypeChange
        ? new OSM()
        : new BingMaps({
            key: BingMapsKey,
            imagerySet: 'Aerial',
            placeholderTiles: true
          })
    });
    const layers = map.getAllLayers();
    if (layers.length > 0) {
      layers[0] = tileLayer;
      map.setLayers(layers);
    } else {
      map.setLayers([tileLayer]);
    }

    map.getView();

    let currentMapEntity: MapEntity;
    let mapZone: ZoneAssetId;
    //if is on edit Zone
    if (zoneId) {
      mapZone = gameDocument?.rules.worldMap.zones.find(
        (x) => x.zoneAssId === zoneId
      )!;
      currentMapEntity = gameDocument?.assets?.maps?.find(
        (x) => x.id === mapZone?.mapAssId
      )!;
    } else {
      currentMapEntity = gameDocument?.assets?.maps?.find(
        (x) => x.id === mapInfo?.id
      )!;
    }

    const mapEntity: MapEntity = {
      name: currentMapEntity?.name!,
      description: currentMapEntity?.description!,
      id: currentMapEntity?.id!,
      type: mapType,
      titleResId: currentMapEntity?.id,
      longitude: currentMapEntity?.longitude,
      latitude: currentMapEntity?.latitude,
      zoomLevel: currentMapEntity?.zoomLevel,
      url: `https://www.openstreetmap.org/#map=${currentMapEntity?.zoomLevel}/${currentMapEntity?.latitude}/${currentMapEntity?.longitude}"`
    };

    UpdateMapAsync(state?.gameDocument!, currentMapEntity.id, mapEntity!).then(
      (response) => {
        setState((prev) => UpdateGameDocState(prev, response));
      }
    );
  };

  const [moveListener, setMoveListener] = useState<EventsKey | null>(null);
  const [clickListener, setClickListener] = useState<EventsKey | null>(null);

  const [moveListenerKeys, setMoveListenerKeys] = useState<EventsKey[]>([]);
  const [clickListenerKeys, setClickListenerKeys] = useState<EventsKey[]>([]);

  useEffect(() => {
    if (selectedPanel === 'Task' && map) {
      const { id, image, name } = addTaskMode;
      let defaultImage = image;

      if (
        gameDocument?.settings.designer &&
        gameDocument.settings.designer.defaultTaskAvailableIconResId
      ) {
        defaultImage = GetResourceValue(
          gameDocument,
          gameDocument.settings.designer.defaultTaskAvailableIconResId
        );
      }

      addTaskOverlay(id, name, defaultImage, [0, 0], true);

      const moveListenerKey = map.on('pointermove', (event) => {
        const coordinate = event.coordinate;
        updateTaskOverlayPosition(id, toLonLat(coordinate));
      });

      const clickListenerKey = map.on('click', (event) => {
        const coordinate = event.coordinate;
        onAddTask && onAddTask([coordinate[0], coordinate[1]]);
      });

      setMoveListener(moveListenerKey);
      setClickListener(clickListenerKey);

      moveListenerKeys.push(moveListenerKey);
      clickListenerKeys.push(clickListenerKey);
    }
  }, [selectedPanel, map, gameDocument]);

  useEffect(() => {
    if (selectedPanel !== 'Task' && map) {
      if (moveListener) unByKey(moveListener);
      if (clickListener) unByKey(clickListener);

      if (moveListenerKeys.length > 0) {
        moveListenerKeys.forEach((moveListenerKey) => {
          unByKey(moveListenerKey);
        });
      }

      if (clickListenerKeys.length > 0) {
        clickListenerKeys.forEach((clickListenerKey) => {
          unByKey(clickListenerKey);
        });
      }
      removeTaskOverlay(addTaskMode.id);
    }
  }, [selectedPanel, map, moveListener, clickListener]);

  const [imageRes, setImageRes] = useState<{
    width: number;
    height: number;
  } | null>(null);
  const imageUrl = useMemo(() => {
    if (imageOverlay) {
      return GetResourceValue(state?.gameDocument!, imageOverlay.imageResId!);
    }
    return '';
  }, [state.gameDocument, imageOverlay]);
  const imageOpacity = imageOverlay?.opacity ?? 1;
  const imageCenter = imageOverlay?.center ?? [0, 0];
  const imageScale = imageOverlay?.scale ?? [100, 100];
  const imageLayerRef = useRef<ImageLayer<GeoImage> | null>(null);
  const vectorLayerRef = useRef<VectorLayer | null>(null);

  const updateOverlayImage = (
    newCenter: [number, number],
    newScale: [number, number],
    newExtent: [number, number, number, number]
  ) => {
    if (state.gameDocument) {
      const updatedGameDocument = cloneDeep(state.gameDocument);
      if (updatedGameDocument?.assets.maps) {
        const currentMapId = updatedGameDocument.assets.maps.findIndex(
          (map) => map.id === mapInfo?.id
        );
        if (currentMapId !== -1) {
          const newMapEntity: MapEntity = {
            ...updatedGameDocument.assets.maps[currentMapId],
            imageOverlay: {
              ...imageOverlay!,
              scale: newScale,
              center: newCenter,
              extent: newExtent
            }
          };
          updatedGameDocument.assets.maps.splice(
            currentMapId,
            1,
            newMapEntity as MapEntity
          );
          setState((prev) => UpdateGameDocState(prev, updatedGameDocument));
        }
      }
    }
  };

  useEffect(() => {
    if (!map) return;

    const layers = map.getAllLayers();
    const existingImageLayer = layers.find(
      (layer) => layer.get('id') === 'gpsOverlay'
    );

    if (!imageUrl) return;

    const image = new Image();
    image.src = imageUrl;
    image.onload = () => {
      setImageRes({
        width: image.width * 100,
        height: image.height * 100
      });
    };

    const addOrUpdateImage = () => {
      const source = new GeoImage({
        url: imageUrl,
        imageCenter: imageCenter,
        imageScale: imageScale
      });

      const newImageLayer = new ImageLayer({
        source,
        properties: {
          id: 'gpsOverlay'
        },
        opacity: imageOpacity
      });

      imageLayerRef.current = newImageLayer;
      map.setLayers([
        layers[0],
        newImageLayer,
        ...(layers.length > 1 ? layers.slice(1) : [])
      ]);
    };

    if (existingImageLayer) {
      map.removeLayer(existingImageLayer);
    }

    addOrUpdateImage();

    return () => {
      if (imageLayerRef.current) {
        map.removeLayer(imageLayerRef.current);
        imageLayerRef.current = null;
      }
    };
  }, [map, imageUrl]);

  useEffect(() => {
    if (imageLayerRef.current) {
      imageLayerRef.current.setOpacity(imageOpacity);
    }
  }, [imageOpacity]);

  const transformEventRef = useRef<((evt: any) => void) | null>(null);

  const transformEvent = useCallback(
    (evt: any) => {
      const source = imageLayerRef.current?.getSource();
      if (source && imageRes) {
        const feature: Feature = evt.feature;
        const extent = feature.getGeometry()?.getExtent();
        if (extent) {
          const newCenter = getCenter(extent!) as [number, number];
          source.setCenter(newCenter);

          const originalWidth = imageRes.width;
          const originalHeight = imageRes.height;
          const newWidth = extent[2] - extent[0];
          const newHeight = extent[3] - extent[1];
          const scaleX = (newWidth / originalWidth) * 100;
          const scaleY = (newHeight / originalHeight) * 100;
          source.setScale([scaleX, scaleY]);

          updateOverlayImage(
            newCenter,
            [scaleX, scaleY],
            extent as [number, number, number, number]
          );
        }
      }
    },
    [state.gameDocument, imageLayerRef.current, imageRes, updateOverlayImage]
  );

  useEffect(() => {
    transformEventRef.current = transformEvent;
  }, [transformEvent]);

  useEffect(() => {
    if (!map || !mapInfo || !imageUrl || !imageRes || !imageLayerRef.current)
      return;
    const layers = map.getAllLayers();

    if (vectorLayerRef.current) {
      map.removeLayer(vectorLayerRef.current);
      map.getInteractions().forEach((interaction) => {
        if (interaction instanceof Transform) {
          map.removeInteraction(interaction);
        }
      });
      vectorLayerRef.current = null;
    }

    const source = imageLayerRef.current.getSource();
    if (source) {
      const imageExtent = source.getExtent();
      const imageFeature = new Feature({
        geometry: new Polygon([
          [
            [imageExtent[0], imageExtent[1]],
            [imageExtent[0], imageExtent[3]],
            [imageExtent[2], imageExtent[3]],
            [imageExtent[2], imageExtent[1]],
            [imageExtent[0], imageExtent[1]]
          ]
        ])
      });

      const vectorSource = new VectorSource({
        features: [imageFeature]
      });

      const vectorLayer = new VectorLayer({
        source: vectorSource,
        properties: { id: 'gpsOverlayVector' },
        style: new Style({
          fill: new Fill({
            color: 'rgba(0,0,0,0)'
          })
        })
      });
      vectorLayerRef.current = vectorLayer;

      const transformInteraction = new Transform({
        //@ts-ignore
        features: [imageFeature],
        scale: true,
        rotate: false,
        stretch: false,
        keepAspectRatio: () => true
      });

      const newLayers = [
        layers[0],
        layers[1],
        vectorLayer,
        ...(layers.length > 2 ? layers.slice(2) : [])
      ];
      map.setLayers(newLayers);

      map.addInteraction(transformInteraction);
      const transformeListener = (evt: any) => {
        if (transformEventRef.current) {
          transformEventRef.current(evt);
        }
      };
      transformInteraction.on(['translateend', 'scaleend'], transformeListener);
    }

    return () => {
      if (vectorLayerRef.current) {
        map.removeLayer(vectorLayerRef.current);
        map.getInteractions().forEach((interaction) => {
          if (interaction instanceof Transform) {
            map.removeInteraction(interaction);
          }
        });
        vectorLayerRef.current = null;
      }
    };
  }, [
    map,
    mapInfo,
    imageUrl,
    imageRes?.width,
    imageRes?.height,
    imageLayerRef.current
  ]);

  return (
    <>
      <div className={'map-canvas'}>
        <div id={'map'} ref={mapRef as any} className={'map'}></div>
        <MoveMapPad
          onViewChange={onViewChange}
          isOpenStreetMap={isOpenStreetMap}
        />
      </div>
      {showImageEditor && (
        <MapImagePropertyEditorWindow
          id={selectedImageId}
          onClose={() => setShowImageEditor(false)}
          onChange={() => {}}
        />
      )}
    </>
  );
};

export default MapCanvas;
