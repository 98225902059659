import { Geolocation, Place } from '../types/place';
import { GetAllAsync, GetAsync } from './api';
import redaxios from 'redaxios';

interface SearchResult {
  predictions: Place[];
}

interface GeolocationResult {
  status: string;
  results: Geolocation[];
}

const MapEndpoint = `maps`;

export const SearchPlaceAsync = async (keyword: string) =>
  redaxios.get<Place[]>(
    `https://nominatim.openstreetmap.org/search?q=${keyword}&format=json&addressdetails=1&limit=5`
  );

export const GetGeolocationAsync = async (placeId: string) =>
  GetAsync<GeolocationResult>(`${MapEndpoint}/GetPlace?input=${placeId}`);
