import { GameDocumentContext } from '../../../contexts/game-document';
import { useContext, useEffect, useState } from 'react';
import Toolbar from '../toolbar';
import { Col, Row } from 'react-bootstrap';
import {
  AddResourceAsync,
  DeleteResourceAsync,
  GetResourceById,
  GetResourceByTypes,
  UpdateGameDocState
} from '../../../utils/game-document';
import {
  GridToolBar,
  GridToolbarDataStateChangeEvent
} from '../../../components/grid/grid-tool-bar';
import {
  Grid,
  GridCellProps,
  GridColumn as Column,
  GridDataStateChangeEvent,
  GridNoRecords
} from '@progress/kendo-react-grid';
import { NoRecords } from '../../../components/grid/no-records';
import { process, State } from '@progress/kendo-data-query';
import { DefaultGridSettings } from '../../../constants/grid-settings';
import { ActionCell } from '../../../components/grid/action-cell';
import { ResourceType } from '../../../types/game-document/resource-type';
import { Resource as ResourceEntity } from '../../../types/game-document/entities/resource';
import { YesNoDialog } from '../../../components/dialogs/yes-no-dialog';
import { YesNoDialogResult } from '../../../types/dialog-result';
import { Button } from '@progress/kendo-react-buttons';
import {
  ResourceWindow,
  UploadedImage
} from '../../../features/game-document/image-resource/resource-window';
import cloneDeep from 'lodash.clonedeep';
import { CellWithThumbnail } from '../../../components/grid/cell-with-thumbnail';
import Dialog from '../../../components/dialogs/dialog';
import { useSessionStorage } from 'usehooks-ts';
import {
  CardViewDataStateChangeEvent,
  ResourcesCardView
} from '../../../features/resource/resource-cardview';
import { useGameDocumentResources } from '../../../hooks/use-game-document-resources';
import MediaPreviewDialog from '../../../components/dialogs/media-preview-dialog';

const initialDataState: State = {
  sort: [{ field: 'name', dir: 'asc' }],
  ...DefaultGridSettings.initialDataState
};

const Resource = () => {
  const [state, setState] = useContext(GameDocumentContext);
  const [dataState, setDataState] = useSessionStorage<State>(
    'datastate-game-resources',
    initialDataState
  );
  const [resources, setResources] = useState<ResourceEntity[]>([]);
  const [selectedId, setSelectedId] = useState<string>('');
  const [selectedUrl, setSelectedUrl] = useState<string>('');
  const [selectedType, setSelectedType] = useState<string>('');
  const [selectedTitle, setSelectedTitle] = useState<string>('');
  const [showUpload, setShowUpload] = useState<boolean>(false);
  const [showImage, setShowImage] = useState<boolean>(false);
  const [showEdit, setShowEdit] = useState<boolean>(false);
  const [showDeleteResourceConfirm, setShowDeleteResourceConfirm] =
    useState<boolean>(false);

  const [gridMode, setGridMode] = useState<string>('card');
  const resourceFiles = useGameDocumentResources();

  /**
   * Custom cell for delete row
   * @param props
   * @returns
   */
  const DeleteCell = (props: GridCellProps) => (
    <td>
      <ActionCell
        title={'Preview'}
        onClick={() =>
          onPreviewImage(
            props.dataItem['value'],
            props.dataItem['name'],
            props.dataItem['type']
          )
        }
        icon={'visibility'}
        themeColor={'primary'}
        isDisable={
          props.dataItem.type === 'text' || props.dataItem.value === ''
        }
      />
      <ActionCell
        title={'Edit'}
        onClick={() =>
          onEditResource(
            props.dataItem['id'],
            props.dataItem['value'],
            props.dataItem['name'],
            props.dataItem['type']
          )
        }
        icon={'edit'}
        themeColor={'primary'}
      />
      <ActionCell
        title={'Delete'}
        onClick={() => onDeleteResource(props.dataItem['id'])}
        icon={'delete'}
        themeColor={'error'}
      />
    </td>
  );

  /**
   * Get icon from resource type
   * @param type
   * @returns
   */
  const getIconName = (type: ResourceType) => {
    switch (type) {
      case 'video':
        return 'play_circle';
      default:
        return type;
    }
  };

  /**
   * Custom cell for name field
   * @param props
   * @returns
   */
  const NameCell = (props: GridCellProps) => {
    return (
      <CellWithThumbnail
        text={props.dataItem['name']}
        type={props.dataItem.type}
        url={getIconName(props.dataItem['value'])}
      />
    );
  };

  const onDeleteResource = (id: string) => {
    resourceInUseCheck(id);
  };

  const onEditResource = (
    id: string,
    url: string,
    name: string,
    type: string
  ) => {
    setSelectedId(id);
    setSelectedUrl(url);
    setSelectedTitle(name);
    setShowEdit(true);
    setSelectedType(type);
  };

  const onSubmitEdit = (uploadedFile: UploadedImage) => {
    resourceFiles.updateResourceFile(selectedId, uploadedFile, (response) => {
      setSelectedUrl('');
      setSelectedTitle('');
      setShowEdit(false);
      setSelectedType('');
    });
  };

  const onPreviewImage = (url: string, name: string, type: string) => {
    setSelectedUrl(url);
    setSelectedTitle(name);
    setShowImage(true);
    setSelectedType(type);
  };

  const resourceInUseCheck = async (id: string) => {
    if (state.gameDocument) {
      let gameDocument = JSON.stringify(cloneDeep(state.gameDocument));
      if (gameDocument.split(id).length > 2) {
        setSelectedId(id);
        setShowDeleteResourceConfirm(true);
      } else {
        const response = await DeleteResourceAsync(state.gameDocument!, id);
        setState((prev) => UpdateGameDocState(prev, response));
      }
    }
  };

  const onMultipleFilesHandler = (files: UploadedImage[]) => {
    files?.forEach(async (file) => {
      const response = await AddResourceAsync(
        state.gameDocument!,
        file.fileName,
        '',
        file.mimeType?.split('/')[0],
        file.blobUrl,
        file.resource?.id,
        file.size
      );
      setState((prev) => UpdateGameDocState(prev, response));
    });
    setShowUpload(!showUpload);
  };

  const onConfirmDeleteResource = async (result: YesNoDialogResult) => {
    if (result === 'yes') {
      const response = await DeleteResourceAsync(
        state.gameDocument!,
        selectedId
      );
      setState((prev) => UpdateGameDocState(prev, response));
    }

    setShowDeleteResourceConfirm(false);
  };

  useEffect(() => {
    if (state.gameDocument) {
      setResources(
        GetResourceByTypes(state.gameDocument!, [
          'video',
          'image',
          'audio'
        ]).filter((item) => item.name) ?? []
      );
    }
  }, [state.gameDocument]);

  return (
    <>
      <Toolbar title={'Resource'} showDefaultContent={true}>
        <Button
          themeColor={'primary'}
          onClick={() => {
            setShowUpload(!showUpload);
          }}
          className={'mr-1'}>
          Upload files
        </Button>
      </Toolbar>
      <hr />
      <Row>
        <Col className={'d-flex flex-column'}>
          <div className={'pt-2'}>
            <GridToolBar
              searchPlaceholder={'Search resource'}
              columnsToSearch={['name', 'description']}
              {...dataState}
              onDataStateChange={(e: GridToolbarDataStateChangeEvent) => {
                setDataState(e.dataState);
              }}
              onGridModeChange={(e) => {
                setGridMode(e.gridMode);
              }}
              defaultGridMode="card"
            />
            {gridMode === 'grid' && (
              <Grid
                pageable={{ info: true, pageSizes: [20, 50, 100] }}
                pageSize={20}
                sortable={true}
                className={'cg-grid3'}
                data={process(resources, dataState)}
                {...dataState}
                onDataStateChange={(e: GridDataStateChangeEvent) => {
                  setDataState(e.dataState);
                }}>
                <Column field={'name'} title={'Name'} cell={NameCell} />
                <Column cell={DeleteCell} width={120} />
                <GridNoRecords>
                  <NoRecords />
                </GridNoRecords>
              </Grid>
            )}

            {gridMode === 'card' && (
              <ResourcesCardView
                data={process(resources, dataState).data}
                onNameClick={(resourceEntity) => {
                  onPreviewImage(
                    resourceEntity.value!,
                    resourceEntity.name,
                    resourceEntity.type!
                  );
                }}
                onDeleteClick={(id) => {
                  onDeleteResource(id);
                }}
                onEditClick={(resource) => {
                  onEditResource(
                    resource.id,
                    resource.value!,
                    resource.name,
                    resource.type!
                  );
                }}
                total={resources.length}
                {...dataState}
                onDataStateChange={(e: CardViewDataStateChangeEvent) => {
                  setDataState(e.dataState);
                }}
              />
            )}
          </div>
        </Col>
      </Row>

      {showDeleteResourceConfirm && (
        <YesNoDialog
          title={'Confirm removal'}
          onConfirm={onConfirmDeleteResource}
          onClose={() => setShowDeleteResourceConfirm(false)}>
          Are you sure you want to remove the resource? The resource is being
          used on the Game.
        </YesNoDialog>
      )}

      {showUpload && (
        <ResourceWindow
          title={'Upload files'}
          onSubmitMultipleFiles={onMultipleFilesHandler}
          toggleDialog={() => {
            setShowUpload(!showUpload);
          }}
          isMultipleFiles={true}
          acceptedExtension={'image/*, video/*, audio/*'}
          hideResourcesTab={true}
          hideUrlTab={true}
        />
      )}

      {showEdit && (
        <ResourceWindow
          title={'Upload files'}
          onSubmit={(uploadedFile) => {
            onSubmitEdit(uploadedFile);
          }}
          toggleDialog={() => {
            setShowEdit(!showEdit);
          }}
          imageUrl={selectedUrl}
          imageSource={selectedUrl}
          acceptedExtension={`${selectedType}/*`}
          hideResourcesTab={true}
        />
      )}

      {showImage && (
        <MediaPreviewDialog
          title={selectedTitle}
          mediaType={selectedType}
          mediaUrl={selectedUrl}
          onClose={() => setShowImage(false)}
        />
      )}
    </>
  );
};

export default Resource;
